// import React from 'react';
// import './about.css';

// export const About = () => {
  
//   const steps = [
//     { id: 1, name: 'Define Your Goals', imgSrc: '/assests/img5.jpg' },
//     { id: 2, name: 'Create a Plan', imgSrc: '/assests/img6.jpg' },
//     { id: 3, name: 'Track Your Progress', imgSrc: '/assests/img7.jpg' },
//     { id: 4, name: 'Stay Committed', imgSrc: '/assests/img8.jpg' }
//   ];
//   return (
//     <div className="homepage1">
//     <main className="content1">
//     <h1  className="style2" style={{marginRight:'40px',color:'Darkblue',textDecoration:'underline',padding:'25px'}}>"Achieve Financial Success with Goal Planning"</h1><br/>

//     <p style={{textAlign:'center',color:'Darkblue',fontWeight: 'bold'}}> Why Goal Planning Matters ?</p>
// <p className="justify-text" style={{color:'blue',textAlign: 'justify',marginLeft:'300px'}}>
// "Goal planning is crucial for financial success. Without clear goals, it's difficult to navigate your financial<br/> journey or 
//  determine the steps needed to reach your destination.Setting specific,measurable,achievable,<br/> relevant, 
// and time-bound (SMART) financial goals helps you create a roadmap for your financial future."</p><br/><br/>
// <p style={{textAlign:'center',color:'Darkblue',fontWeight: 'bold'}}> How Goal Planning Works ? <br/></p>
// <p  className="justify-text" style={{textAlign: 'justify',marginLeft:'250px',color:'blue', }}>
// "The process begins by identifying your financial objectives. Whether your aim is to buy a house, save for retirement, 
// <br/>or start a business, having clear goals allows you to prioritize spending and concentrate on what matters most. 
// With <br/> your goals defined, you can then develop a plan to achieve them, breaking each goal into smaller, manageable steps."</p><br/>
// <br/>
// <p style={{textAlign:'center',color:'Darkblue',fontWeight: 'bold',textDecoration:'underline'}}>How to Get Started</p>
// <p  className="justify-text1" style={{textAlign:'center',color:'blue'}}>
// <div className="image-row1">
//         {steps.map(step => (
//           <div key={step.id} className="image-item">
//             <img src={step.imgSrc} alt={step.name} />
//             <p>{step.name}</p>
//           </div>
//         ))}
//       </div>
//     </p> 
//     </main>
//   </div>
//   );
// }


// export default About;

// import React from 'react';
// import './about.css';

// export const About = () => {
//   const steps = [
//     { id: 1, name: 'Define Your Goals', imgSrc: '/assets/img5.jpg' },
//     { id: 2, name: 'Create a Plan', imgSrc: '/assets/img6.jpg' },
//     { id: 3, name: 'Track Your Progress', imgSrc: '/assets/img7.jpg' },
//     { id: 4, name: 'Stay Committed', imgSrc: '/assets/img8.jpg' }
//   ];

//   return (
//     <div className="homepage1">
//       <main className="content1">
//         <h1 className="style2" style={{ marginRight: '40px', color: 'Darkblue', textDecoration: 'underline', padding: '25px' }}>
//           "Achieve Financial Success with Goal Planning"
//         </h1><br />

//         <p style={{ textAlign: 'center', color: 'Darkblue', fontWeight: 'bold' }}>Why Goal Planning Matters?</p>
//         <p className="justify-text" style={{ color: 'blue', textAlign: 'justify', marginLeft: '300px' }}>
//           "Goal planning is crucial for financial success. Without clear goals, it's difficult to navigate your financial<br />
//           journey or determine the steps needed to reach your destination. Setting specific, measurable, achievable,<br />
//           relevant, and time-bound (SMART) financial goals helps you create a roadmap for your financial future."
//         </p><br /><br />
//         <p style={{ textAlign: 'center', color: 'Darkblue', fontWeight: 'bold' }}>How Goal Planning Works?<br /></p>
//         <p className="justify-text" style={{ textAlign: 'justify', marginLeft: '250px', color: 'blue' }}>
//           "The process begins by identifying your financial objectives. Whether your aim is to buy a house, save for retirement,<br />
//           or start a business, having clear goals allows you to prioritize spending and concentrate on what matters most.<br />
//           With your goals defined, you can then develop a plan to achieve them, breaking each goal into smaller, manageable steps."
//         </p><br /><br />
//         <p style={{ textAlign: 'center', color: 'Darkblue', fontWeight: 'bold', textDecoration: 'underline' }}>How to Get Started</p>
//         <p className="justify-text1" style={{ textAlign: 'center', color: 'blue' }}>
//           <div className="image-row1">
//             {steps.map(step => (
//               <div key={step.id} className="image-item">
//                 <img src={step.imgSrc} alt={step.name} />
//                 <p>{step.name}</p>
//               </div>
//             ))}
//           </div>
//         </p>
//       </main>
//     </div>
//   );
// }

// export default About;


import React from 'react';
import './about.css';

export const About = () => {
  const steps = [
    { id: 1, name: 'Define Your Goals', imgSrc: '/assests/img5.jpg' },
    { id: 2, name: 'Create a Plan', imgSrc: '/assests/img6.jpg' },
    { id: 3, name: 'Track Your Progress', imgSrc: '/assests/img7.jpg' },
    { id: 4, name: 'Stay Committed', imgSrc: '/assests/img8.jpg' }
  ];

  return (
    <div className="homepage">
      <main className="content">
        <h1 className="style1" style={{ marginRight: '40px', color: 'Darkblue', textDecoration: 'underline', padding: '25px' }}>
          "Achieve Financial Success with Goal Planning"
        </h1><br/>
        <p style={{ textAlign: 'center', color: 'Darkblue', fontWeight: 'bold' }}>Why Goal Planning Matters?</p>
        <p className="justify-text" style={{ color: 'blue', textAlign: 'justify', marginLeft: '300px' }}>
          "Goal planning is crucial for financial success. Without clear goals, it's difficult to navigate your financial<br/> journey or determine the steps needed to reach your destination.Setting specific, measurable, achievable,<br/> relevant, and time-bound (SMART) financial goals helps you create a roadmap for your financial future."
        </p><br/><br/>
        <p style={{ textAlign: 'center', color: 'Darkblue', fontWeight: 'bold' }}>How Goal Planning Works?<br/></p>
        <p className="justify-text" style={{ textAlign: 'justify', marginLeft: '250px', color: 'blue' }}>
          "The process begins by identifying your financial objectives. Whether your aim is to buy a house, save for retirement,<br/>or start a business, having clear goals allows you to prioritize spending and concentrate on what matters most. With<br/> your goals defined, you can then develop a plan to achieve them, breaking each goal into smaller, manageable steps."
        </p><br/><br/>
        <p style={{ textAlign: 'center', color: 'Darkblue', fontWeight: 'bold', textDecoration: 'underline' }}>How to Get Started</p>
        <p className="justify-text" style={{ textAlign: 'center', color: 'blue' }}>
          <div className="image-row">
            {steps.map(step => (
              <div key={step.id} className="image-item">
                <img src={step.imgSrc} alt={step.name} />
                <p>{step.name}</p>
              </div>
            ))}
          </div>
        </p>
        <p className="footcontent" style={{ color: 'black', fontWeight: 'bold'}}>
          Start Goal Planning Today "Don't delay in planning for your financial future.<br /> By setting clear goals and devising a plan to achieve them, you can take control of your finances and<br /> work towards a secure and prosperous future."
        </p>
      </main>
      <footer className="footer">
        <p>&copy; 2024 Zieers System Pvt Ltd</p>
        <nav className="footer-nav">
          <a href="/terms">Terms</a>
          <a href="/privacy">Privacy Policy</a>
        </nav>
      </footer>
    </div>
  );
}

export default About;
