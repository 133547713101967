import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import jsPDF from 'jspdf';

const WealthCreation = () => {
  const [name, setName] = useState('');
  const [currentSavings, setCurrentSavings] = useState('');
  const [regularContribution, setRegularContribution] = useState('');
  const [returnRate, setReturnRate] = useState('');
  const [investmentDuration, setInvestmentDuration] = useState('');
  const [futureInvestmentValue, setFutureInvestmentValue] = useState(0);
  const [uploadedImage, setUploadedImage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const displayImage = (event) => {
    const reader = new FileReader();
    reader.onload = function () {
      setUploadedImage(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handlePrint = () => {
    const pdf = new jsPDF();

    const content = `
      Dear ${name}, 

      Investment Planning Details:

      Current Savings: ${currentSavings}
      Regular Contributions: ${regularContribution}
      Expected Return Rate (%): ${returnRate}%
      Investment Duration (Years): ${investmentDuration}

      Projected Future Investment Value: ${futureInvestmentValue.toFixed(0)}
    `;

    pdf.text(content, 10, 10);
    pdf.save('investment_plan.pdf');
  };

  const handleCalculate = () => {
    const currentSavingsFloat = parseFloat(currentSavings);
    const regularContributionFloat = parseFloat(regularContribution);
    const returnRateFloat = parseFloat(returnRate) / 100;
    const investmentDurationInt = parseInt(investmentDuration);

    const futureValue =
      currentSavingsFloat * Math.pow(1 + returnRateFloat, investmentDurationInt) +
      regularContributionFloat *
        ((Math.pow(1 + returnRateFloat, investmentDurationInt) - 1) / returnRateFloat);

    setFutureInvestmentValue(futureValue);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>Investment Calculator</h3>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 grid-margin stretch-card text-center" style={{ display: 'block' }}>
            <div className="card">
              <div className="card-body">
                <div className="image-upload">
                  <div className="content_img">
                    <input type="file" id="myFile" onChange={displayImage} />
                    <img id="uploadedImage" src={uploadedImage} alt="Uploaded" height="220px" width="210px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 grid-margin stretch-card" style={{ display: 'block' }}>
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <form id="investmentForm">
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="name" className="card-title">Name</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="name"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="currentSavings" className="card-title">Current Savings</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="currentSavings"
                                placeholder="Current Savings"
                                value={currentSavings}
                                onChange={(e) => setCurrentSavings(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="regularContribution" className="card-title">Regular Contributions</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="regularContribution"
                                placeholder="Regular Contributions"
                                value={regularContribution}
                                onChange={(e) => setRegularContribution(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="returnRate" className="card-title">Expected Return Rate (%)</label>
                              <input
                                type="number"
                                className="form-control text-size"
                                id="returnRate"
                                placeholder="Return Rate"
                                value={returnRate}
                                onChange={(e) => setReturnRate(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="investmentDuration" className="card-title">Investment Duration (Years)</label>
                              <input
                                type="number"
                                className="form-control text-size"
                                id="investmentDuration"
                                placeholder="Investment Duration"
                                value={investmentDuration}
                                onChange={(e) => setInvestmentDuration(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button type="button" className="btn btn-primary" onClick={handleCalculate}>Calculate</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal component */}
      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Dear {name}<br />Investment Planning</h5>
                <button type="button" className="close" onClick={closeModal} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="custom-container">
                  <span className="span1"><strong>Current Savings:</strong></span>
                  <span className="span2">{currentSavings}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Regular Contributions:</strong></span>
                  <span className="span2">{regularContribution}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Expected Return Rate (%):</strong></span>
                  <span className="span2">{returnRate}%</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Investment Duration (Years):</strong></span>
                  <span className="span2">{investmentDuration}</span>
                </div>
                <h4>Projected Future Investment Value</h4>
                <div className="custom-container">
                  <span className="span1"><strong>Future Investment Value:</strong></span>
                  <span className="span2">{futureInvestmentValue.toFixed(0)}</span>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handlePrint}>Print</button>
                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WealthCreation;
