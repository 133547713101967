import React, { useState, useEffect } from 'react';
import { Link, Route, Routes,useNavigate } from 'react-router-dom';
import { Dropdown, Button, Menu } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import './index.css';
import About from './about';
import Contact from './contact';
import Login from './login';
import Signup from './signup';
import Reset from './reset';
import Profile from './profile';
import Clientdashboard from './clientDashBoard';
import Changepassword from './changepassword';
import { checkSession } from './apiservices';

const Index = () => {
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    const verifySession = async () => {
      const response = await checkSession();
      if (response.success) {
        setUsername(response.user.name);
        localStorage.setItem('username', response.user.name);
      } else {
        // Clear local storage if session is invalid
        localStorage.removeItem('token');
        localStorage.removeItem('username');
      }
      setIsLoading(false);
    };

    verifySession();
    // Fetch the username from local storage when component mounts
    const storedUsername = localStorage.getItem('username');
    setUsername(storedUsername || '');
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setUsername(''); // Clear the username state
    navigate('/login'); // Navigate to the login page
  };

  const handleLogoClick = () => {
        navigate('/');
     };

  const userMenu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/clientDashBoard">Client Dashboard</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/profile">Profile</Link>
      </Menu.Item>
      <Menu.Item key="4">
      <Link to="/changepassword">Change Password</Link>
      </Menu.Item>
      <Menu.Item key="3" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }


  return (
    <div className="homepage">
      <header className="header" style={{ backgroundColor: '#4ac8eb', height: '80px' }}>
      <div className="logo" style={{marginRight:'1000px'}}> 
         <img src="assests/logo1.jpg" alt="Logo" style={{height: '70px',width:'70px',borderRadius:'50%',objectFit:'cover'}}  onClick={handleLogoClick} /> {/* Update the path and styling as needed */}         </div>

        <nav className="nav" style={{ marginRight: '20px' }}>
          <Link to="/about">About Us</Link>
          <Link to="/contact">Contact Us</Link>
          {username ? (
            <Dropdown overlay={userMenu} trigger={['click']}>
              <Button className="user-button" style={{ width: '120px' }}>
                <UserOutlined style={{ marginRight: 8 }} />
                {username || "User"} <DownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <Link to="/login">Login/Signup</Link>
          )}
        </nav>
      </header>
      <main className="content">
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/login" element={<Login setUsername={setUsername} />} />
          <Route path='/clientDashBoard/*' element={<Clientdashboard />} />
          <Route path='/changepassword' element={<Changepassword />} />
         <Route path='/profile' element={<Profile />} />
        </Routes>
      </main>
    </div>
  );
};

export default Index;
