
import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import './dashboard.css';

const apiUrl = process.env.REACT_APP_API_URL;
const Dashboard = () => {
  const [totals, setTotals] = useState({
    clients: 0,
    goals: 0,
    sips: 0,
    lumpsum: 0,
  });

  useEffect(() => {
    // Fetch data from the database
    // const fetchData = async () => {
    //   try {
    //     const response = await fetch(`${apiUrl}/getTotal.php`);
    //     const totalsData = await response.json();
    //     setTotals(totalsData);
    //   } catch (error) {
    //     console.error('Failed to fetch data', error);
    //   }
    // };

    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/getTotal.php`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const totalsData = await response.json();
        setTotals(totalsData);
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };
    

    fetchData();
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={16}>
        <Col span={6}>
          <Card className="total-clients-box" bordered={false}>
            <div className="summary-box-content">
              <div className="summary-box-title">Total Clients</div>
              <div className="summary-box-number">{totals.clients}</div>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card className="total-goals-box" bordered={false}>
            <div className="summary-box-content">
              <div className="summary-box-title">Total Goals</div>
              <div className="summary-box-number">{totals.goals}</div>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card className="total-sips-box" bordered={false}>
            <div className="summary-box-content">
              <div className="summary-box-title">Total SIPs</div>
              <div className="summary-box-number">{totals.sips}</div>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card className="total-lumpsum-box" bordered={false}>
            <div className="summary-box-content">
              <div className="summary-box-title">Total Lumpsum</div>
              <div className="summary-box-number">{totals.lumpsum}</div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
