import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, Modal, Form, Input, DatePicker, message, Select } from 'antd';
import moment from 'moment';

const AssignFunds = () => {
  const [funds, setFunds] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentFund, setCurrentFund] = useState(null);
  const [form] = Form.useForm();
  const [sipAmount, setSipAmount] = useState(null);
  const [lumpsumAmount, setLumpsumAmount] = useState(null);
  const [goalOptions, setGoalOptions] = useState([]);
  const [clientEmails, setClientEmails] = useState([]);

  const getGoalNameById = (id) => {
    const goal = goalOptions.find((option) => option.value === id);
    return goal ? goal.label : id;
  };

  const getEmailById = (id) => {
    const client = clientEmails.find((option) => option.value === id);
    return client ? client.label : id;
  };

  const fetchFunds = async () => {
    try {
      const response = await fetch('http://localhost:5000/api/fund', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const mappedFunds = data.map((fund) => ({
        ...fund,
        goalName: getGoalNameById(fund.goalName),
        email: getEmailById(fund.email),
      }));
      setFunds(mappedFunds);
    } catch (error) {
      message.error('Failed to fetch funds');
    }
  };

  const fetchGoalOptions = useCallback(async () => {
    try {
      const response = await fetch('http://localhost:5000/api/goals');
      const data = await response.json();
      if (data.success) {
        const options = data.data.map((goal) => ({ label: goal.name, value: goal._id }));
        setGoalOptions(options);
      }
    } catch (error) {
      console.error('Error fetching goal options:', error);
    }
  }, []);
  

  const fetchClientEmails = useCallback(async () => {
    try {
      const response = await fetch('http://localhost:5000/api/clients');
      const data = await response.json();
      if (data.success) {
        const emailOptions = data.data.map((client) => ({ label: client.email, value: client._id }));
        setClientEmails(emailOptions);
      }
    } catch (error) {
      console.error('Error fetching client emails:', error);
    }
  }, []);

  useEffect(() => {
    fetchGoalOptions();
    fetchClientEmails();
    fetchFunds();
  }, [fetchGoalOptions, fetchClientEmails]);

  const handleCalculate = () => {
    const values = form.getFieldsValue();
    const expectedCAGR = parseFloat(values.expectedCAGR) / 100;
    const amount = parseFloat(values.amount);

    if (isNaN(expectedCAGR) || isNaN(amount)) {
      message.error('Please enter valid numbers for CAGR and amount');
      return;
    }

    const periods = 60; // assuming 5 years for SIP calculation
    const sipFutureValue = (amount * ((1 + expectedCAGR) ** periods - 1) / expectedCAGR) * (1 + expectedCAGR);
    const lumpsumFutureValue = amount * (1 + expectedCAGR) ** periods;

    setSipAmount(sipFutureValue.toFixed(2));
    setLumpsumAmount(lumpsumFutureValue.toFixed(2));
  };

  const handleAddFund = async () => {
    try {
      const values = form.getFieldsValue();
      const goalName = getGoalNameById(values.goalName); // Mapping goal ID to goal name
      const email = getEmailById(values.email);
      const newFund = {
        ...values,
        email,
        goalName,
        sipAmount,
        lumpsumAmount,
        sipDate: values.sipDate.format('YYYY-MM-DD'),
      };
  
      const response = await fetch('http://localhost:5000/api/fundadd', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newFund),
      });
  
      const data = await response.json();
      if (data.success) {
        setFunds([...funds, {
          ...data.data,
          goalName: getGoalNameById(data.data.goalName),
          email: getEmailById(data.data.email),
        }]);
        message.success('Fund added successfully');
        setModalVisible(false);
        form.resetFields();
      } else {
        message.error('Failed to add fund');
      }
    } catch (error) {
      console.error('Error adding fund:', error);
      message.error('Failed to add fund');
    }
  };
  

  const handleUpdateFund = async () => {
    try {
      const values = form.getFieldsValue();
      const goalName = getGoalNameById(values.goalName); // Mapping goal ID to goal name
      const email = getEmailById(values.email);
      const updatedFund = {
        ...values,
        email,
        goalName,
        sipAmount,
        lumpsumAmount,
        sipDate: values.sipDate.format('YYYY-MM-DD'),
      };

      const response = await fetch(`http://localhost:5000/api/fundupdate/${currentFund._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedFund),
      });

      const data = await response.json();
      if (data.success) {
        setFunds(funds.map(fund => fund._id === currentFund._id ? {
          ...data.data,
          goalName: getGoalNameById(data.data.goalName),
          email: getEmailById(data.data.email),
        } : fund));
        message.success('Fund updated successfully');
      } else {
        message.error('Failed to update fund');
      }

      setModalVisible(false);
      form.resetFields();
      setIsEdit(false);
      setCurrentFund(null);
    } catch (error) {
      console.error('Error updating fund:', error);
      message.error('Failed to update fund');
    }
  };

  const handleEdit = (record) => {
    form.setFieldsValue({
      ...record,
      goalName: record.goalName,
      email: record.email,
      sipDate: moment(record.sipDate),
    });
    setCurrentFund(record);
    setIsEdit(true);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`http://localhost:5000/api/funddelete/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        message.success('Fund deleted successfully');
        fetchFunds();
      } else {
        message.error('Failed to delete fund');
      }
    } catch (error) {
      message.error('Failed to delete fund');
    }
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    form.resetFields();
    setIsEdit(false);
    setCurrentFund(null);
  };

  // const handleFieldChange = async (changedField, value) => {
  //   const fields = form.getFieldsValue();
  //   fields[changedField] = value;
  //   const { goalName, email } = fields;
  
  //   if (goalName && email) {
  //     try {
  //       const response = await fetch('http://localhost:5000/api/goals1/amountRequired', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ goalName, email }),
  //       });
  //       const data = await response.json();
  //       if (data.success) {
  //         form.setFieldsValue({ amount: data.amountRequired });
  //       } else {
  //         message.error('Failed to fetch amount');
  //       }
  //     } catch (error) {
  //       console.error('Error fetching amount:', error);
  //       message.error('Failed to fetch amount');
  //     }
  //   }
  // };

  //changed-------
  const fetchAmountRequired = async (email, goalName) => {
    try {
      const response = await fetch('http://localhost:5000/api/goals1/amountRequired', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, goalName }),
      });
      const data = await response.json();
      if (data.success) {
        form.setFieldsValue({ amount: data.amountRequired });
      } else {
        message.error('Failed to fetch amount');
      }
    } catch (error) {
      console.error('Error fetching amount:', error);
      message.error('Failed to fetch amount');
    }
  };

  const handleFieldChange = async (changedField, value) => {
    const fields = form.getFieldsValue();
    fields[changedField] = value;
    const { goalName, email } = fields;

    if (goalName && email) {
      await fetchAmountRequired(email, goalName);
    }
  };
  
  const columns = [
    {
      title: 'Sl No',
      dataIndex: 'slNo',
      key: 'slNo',
    },
    { title: 'Email',
       dataIndex: 'email',
        key: 'email'
       },
    {
      title: 'Goal Name',
      dataIndex: 'goalName',
      key: 'goalName',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Fund House',
      dataIndex: 'fundHouse',
      key: 'fundHouse',
    },
    {
      title: 'Fund Name',
      dataIndex: 'fundName',
      key: 'fundName',
    },
    {
      title: 'Lumpsum Req',
      dataIndex: 'lumpsumAmount',
      key: 'lumpsumAmount',
    },
    {
      title: 'SIP Req',
      dataIndex: 'sipAmount',
      key: 'sipAmount',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => handleEdit(record)}>Edit</Button>
          <Button type="link" danger onClick={() => handleDelete(record._id)}>Delete</Button>
        </span>
      ),
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        dataSource={funds.map((fund, index) => ({ ...fund, slNo: index + 1 }))}
        rowKey="_id"
        pagination={{ pageSize: 6 }} // Added pagination with 10 items per page
      />
      <Button type="primary" onClick={() => {
        setIsEdit(false);
        setModalVisible(true);
      }}>
        Add New Fund
      </Button>

      <Modal
        title={isEdit ? "Edit Fund" : "Add New Fund"}
        visible={modalVisible}
        onCancel={handleModalCancel}

        footer={[
          <Button key="cancel" onClick={handleModalCancel}>Cancel</Button>,
          <Button key="calculate" type="primary" onClick={handleCalculate}>Calculate</Button>,
          <Button key="save" type="primary" onClick={isEdit ? handleUpdateFund : handleAddFund}>
            {isEdit ? "Update" : "Save"}
          </Button>,
        ]}
      >
      
        <Form form={form} layout="vertical">
        <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please select an email' }]}// extra code start from here
          >
            <Select  onChange={(value) => handleFieldChange('email', value)} >
              {clientEmails.map((client) => (
                <Select.Option key={client.value} value={client.value}>
                  {client.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="goalName"
            label={<span>Goal Name <span style={{ color: 'Red' }}>*</span></span>}
            rules={[{ required: true, message: 'Please select a goal' }]}
          >
            <Select  onChange={(value) => handleFieldChange('goalName', value)}>
              {goalOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="amount"
            label={<span>Amount <span style={{ color: 'Red' }}>*</span></span>}
            rules={[{ required: true }]}// extra code
          >
           <Input readOnly />
          </Form.Item>
          <Form.Item
            name="fundHouse"
            label={<span>Fund House <span style={{ color: 'Red' }}>*</span></span>}
            rules={[{ required: true, message: 'Please enter the fund house' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="fundName"
            label={<span>Fund Name <span style={{ color: 'Red' }}>*</span></span>}
            rules={[{ required: true, message: 'Please enter the fund name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="sipDate"
            label={<span>SIP Date <span style={{ color: 'Red' }}>*</span></span>}
            rules={[{ required: true, message: 'Please select a SIP date' }]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item
            name="expectedCAGR"
            label={<span>Expected CAGR (%) <span style={{ color: 'Red' }}>*</span></span>}
            rules={[{ required: true, message: 'Please enter the expected CAGR' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="SIP Amount"
          >
            <Input value={sipAmount} readOnly />
          </Form.Item>
          <Form.Item
            label="Lumpsum Amount"
          >
            <Input value={lumpsumAmount} readOnly />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AssignFunds;
