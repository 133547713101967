
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import jsPDF from 'jspdf';

const Retirement = () => {
  const [name, setName] = useState('');
  const [currentAge, setCurrentAge] = useState('');
  const [retirementAge, setRetirementAge] = useState('');
  const [lifeExpectancy, setLifeExpectancy] = useState('');
  const [currentSavings, setCurrentSavings] = useState('');
  const [expectedExpenses, setExpectedExpenses] = useState('');
  const [inflation, setInflation] = useState('');
  const [investmentReturns, setInvestmentReturns] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [yearsToRetirement, setYearsToRetirement] = useState(0);
  const [retirementCorpus, setRetirementCorpus] = useState(0);
  const [monthlySavingsRequired, setMonthlySavingsRequired] = useState(0);

  const handlePrint = () => {
    const pdf = new jsPDF();

    const content = `
      Dear ${name}, Retirement Planning

      Current Age: ${currentAge}
      Retirement Age: ${retirementAge}
      Life Expectancy: ${lifeExpectancy}
      Current Savings: ${currentSavings}
      Expected Monthly Expenses (in today's value): ${expectedExpenses}
      Inflation Rate: ${inflation}%
      Expected Investment Returns: ${investmentReturns}%

      Calculation based on above assumptions
      Required Retirement Corpus: ${retirementCorpus.toFixed(0)}
      Monthly Savings Required: ${monthlySavingsRequired.toFixed(0)}
    `;

    pdf.text(content, 10, 10);
    pdf.save('retirement_plan.pdf');
  };

  const handleCalculate = () => {
    const currentAgeInt = parseInt(currentAge);
    const retirementAgeInt = parseInt(retirementAge);
    const lifeExpectancyInt = parseInt(lifeExpectancy);
    const currentSavingsFloat = parseFloat(currentSavings);
    const expectedExpensesFloat = parseFloat(expectedExpenses);
    const inflationRateFloat = parseFloat(inflation) / 100;
    const investmentReturnsFloat = parseFloat(investmentReturns) / 100;

    const yearsToRetirement = retirementAgeInt - currentAgeInt;
    setYearsToRetirement(yearsToRetirement);

    const yearsPostRetirement = lifeExpectancyInt - retirementAgeInt;
    const adjustedExpenses = expectedExpensesFloat * Math.pow(1 + inflationRateFloat, yearsToRetirement);
    const retirementCorpus = adjustedExpenses * 12 * yearsPostRetirement;
    setRetirementCorpus(retirementCorpus);

    const monthlySavingsRequired = (retirementCorpus - currentSavingsFloat) / (yearsToRetirement * 12);
    setMonthlySavingsRequired(monthlySavingsRequired);

    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>Retirement Savings Calculator</h3>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card" style={{ display: 'block' }}>
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <form id="retirementForm">
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="name" className="card-title">Name</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="name"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label htmlFor="currentAge" className="card-title">Current Age</label>
                              <input
                                type="number"
                                className="form-control text-size"
                                id="currentAge"
                                placeholder="Current Age"
                                value={currentAge}
                                onChange={(e) => setCurrentAge(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="retirementAge" className="card-title">Retirement Age</label>
                              <input
                                type="number"
                                className="form-control text-size"
                                id="retirementAge"
                                placeholder="Retirement Age"
                                value={retirementAge}
                                onChange={(e) => setRetirementAge(e.target.value)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label htmlFor="lifeExpectancy" className="card-title">Life Expectancy</label>
                              <input
                                type="number"
                                className="form-control text-size"
                                id="lifeExpectancy"
                                placeholder="Life Expectancy"
                                value={lifeExpectancy}
                                onChange={(e) => setLifeExpectancy(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="currentSavings" className="card-title">Current Savings</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="currentSavings"
                                placeholder="Current Savings"
                                value={currentSavings}
                                onChange={(e) => setCurrentSavings(e.target.value)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label htmlFor="expectedExpenses" className="card-title">Expected Monthly Expenses</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="expectedExpenses"
                                placeholder="Expected Monthly Expenses"
                                value={expectedExpenses}
                                onChange={(e) => setExpectedExpenses(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="inflation" className="card-title">Inflation (%)</label>
                              <input
                                type="number"
                                className="form-control text-size"
                                id="inflation"
                                placeholder="Inflation rate"
                                value={inflation}
                                onChange={(e) => setInflation(e.target.value)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label htmlFor="investmentReturns" className="card-title">Expected Investment Returns (%)</label>
                              <input
                                type="number"
                                className="form-control text-size"
                                id="investmentReturns"
                                placeholder="Expected Investment Returns"
                                value={investmentReturns}
                                onChange={(e) => setInvestmentReturns(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button type="button" className="btn btn-primary" onClick={handleCalculate}>Calculate</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Dear {name},<br /> Retirement Planning</h5>
                <button type="button" className="close" onClick={closeModal} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="custom-container">
                  <span className="span1"><strong>Current Age:</strong></span>
                  <span className="span2">{currentAge}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Retirement Age:</strong></span>
                  <span className="span2">{retirementAge}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Life Expectancy:</strong></span>
                  <span className="span2">{lifeExpectancy}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Current Savings:</strong></span>
                  <span className="span2">{currentSavings}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Expected Monthly Expenses:</strong></span>
                  <span className="span2">{expectedExpenses}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Inflation (%):</strong></span>
                  <span className="span2">{inflation}%</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Expected Investment Returns (%):</strong></span>
                  <span className="span2">{investmentReturns}%</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Years to Retirement:</strong></span>
                  <span className="span2">{yearsToRetirement}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Required Retirement Corpus:</strong></span>
                  <span className="span2">{retirementCorpus.toFixed(0)}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Monthly Savings Required:</strong></span>
                  <span className="span2">{monthlySavingsRequired.toFixed(0)}</span>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                <button type="button" className="btn btn-primary" onClick={handlePrint}>Print</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Retirement;
