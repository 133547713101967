import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, Modal, Form, Input, DatePicker, Select, Popconfirm, message } from 'antd';
import moment from 'moment';

const apiUrl = process.env.REACT_APP_API_URL;
const ClientGoals = () => {
  const [goals, setGoals] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentGoal, setCurrentGoal] = useState(null);
  const [form] = Form.useForm();
  const [goalOptions, setGoalOptions] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const getGoalNameById1 = (id) => {
    const goal = goalOptions.find((option) => option.value === id);
    return goal ? goal.label : id;
  };


  const fetchGoals1 = async (page = 1, pageSize = 5) => {
    try {
      const response = await fetch(`${apiUrl}/getClientGoal.php?page=${page}&pageSize=${pageSize}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action: 'getGoals1' }),
      });
      const data = await response.json();
      if (data.success) {
        setGoals(data.data.map((goal, index) => ({
          ...goal,
          targetDate: moment(goal.targetDate).format('DD-MM-YYYY'),
          slNo: index + 1,
        })));
        setPagination({ ...pagination, current: page, pageSize, total: data.total });
      } else {
        message.error('Failed to fetch goals');
      }
    } catch (error) {
      console.error('Error fetching goals:', error);
      message.error('Error fetching goals');
    }
  };

  const fetchGoalOptions1 = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/getClientGoal.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'getGoalNames' }),
      });
      const data = await response.json();
      if (data.success) {
        const options = data.data.map((goal) => ({
          label: goal.name,
          value: goal.id,
        }));
        setGoalOptions(options);
      }
    } catch (error) {
      console.error('Error fetching goal options:', error);
    }
  }, []);

  useEffect(() => {
    fetchGoalOptions1();
    fetchGoals1(pagination.current, pagination.pageSize);
  }, [fetchGoalOptions1, pagination.current, pagination.pageSize]);

  const addGoal1 = async () => {
    try {
      const values = form.getFieldValue();
      const newGoal = {
        ...values,
        goalName: getGoalNameById1(values.goalName),
        targetDate: values.targetDate.format('YYYY-MM-DD'),
        action :'addGoal1',
      };

      const response = await fetch(`${apiUrl}/getClientGoal.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newGoal),
      });

      const data = await response.json();
  
      if (data.success) {
        setGoals([...goals, {
          ...data.data,
          goalName: getGoalNameById1(data.data.goalName),
        }]);
        
        message.success('Goal added successfully');
        fetchGoals1(pagination.current, pagination.pageSize);
        setIsModalVisible(false);
        form.resetFields();
     } else {
        message.error(data.message || 'Failed to add goal');
     }
    } catch (error) {
      console.error('Error adding goal:', error);
    }
  };

  
  const updateGoal1 = async () => {
    try {
      const values = form.getFieldsValue();
      const goalName = getGoalNameById1(values.goalName);
      const updatedGoal = {
        ...values,
        goalName,
        targetDate: values.targetDate.format('YYYY-MM-DD'),
        action: 'updateGoal1',
        id: currentGoal._id, // Include the 'id' in the request body
      };
  
      const response = await fetch(`${apiUrl}/getClientGoal.php`, {
        method: 'POST', // Assuming your backend expects a POST request for updates
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedGoal),
      });
  
      const data = await response.json();
      if (data.success) {
        setGoals(goals.map(goal => goal._id === currentGoal._id ? {
          ...data.data,
          goalName: getGoalNameById1(data.data.goalName),
        } : goal));
  
        message.success('Goal updated successfully');
        fetchGoals1(pagination.current, pagination.pageSize);
     
      form.resetFields();
      setIsModalVisible(false);
      setCurrentGoal(null);
      setIsEdit(false);
    } else {
      message.error(data.message || 'Failed to update goal');
    }
    } catch (error) {
      console.error('Error updating goal:', error);
      message.error('Failed to update goal');
    }
  };
  


  const handleDelete1  = async (id) => {
  const response = await fetch(`${apiUrl}/getClientGoal.php`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ action: 'deleteGoal1', id }),
      });
      const textResponse = await response.text();
console.log(textResponse); // Log the full response text

// Attempt to parse only if it's valid JSON
try {
    const data = JSON.parse(textResponse);
    if (data.success) {
        // Handle success
    } else {
        message.error(data.message || 'Failed to delete goal');
    }
} catch (error) {
    console.error('Invalid JSON:', textResponse);
}

  //     const result = await response.json();

  //     if (result.success) {
  //         message.success('Goal deleted successfully');
  //         setGoals(goals.filter(goal => goal._id !== id));
  //     } else {
  //         message.error(result.message || 'Failed to delete goal');
  //     }
  // } catch (error) {
  //     console.error('Error deleting goal:', error);
  //     message.error('Failed to delete goal. Please try again later.');
  // }
};


  const handleEdit1 = (record) => {
    form.setFieldsValue({
      goalName: record.goalName,
      targetDate: moment(record.targetDate, 'DD-MM-YYYY'),
      amountRequired: record.amountRequired,
      remarks: record.remarks,
    });
    setCurrentGoal(record);
    setIsEdit(true);
    setIsModalVisible(true);
  };

  const handleOk1 = () => {
    form.submit();
  };

  const handleCancel1 = () => {
    setIsModalVisible(false);
    form.resetFields();
    setIsEdit(false);
    setCurrentGoal(null);
  };

  const columns = [
    // { title: 'ID', dataIndex: '_id', key: '_id' },
    { title: 'Sl No',dataIndex: 'slNo',key: 'slNo'},
    { title: 'Goal Name', dataIndex: 'goalName', key: 'goalName' },
    { title: 'Target Date', dataIndex: 'targetDate', key: 'targetDate' },
    { title: 'Amount Required', dataIndex: 'amountRequired', key: 'amountRequired' },
    { title: 'Remarks', dataIndex: 'remarks', key: 'remarks' },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div>
          <Button type="link" onClick={() => handleEdit1(record)}>Edit</Button>
          <Popconfirm title="Are you sure you want to delete this goal?" onConfirm={() => handleDelete1 (record._id)}>
            <Button type="link" danger>Delete</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={goals.map((goal, index) => ({ ...goal, slNo: index + 1 }))}
        rowKey="_id"
        pagination={pagination}
        onChange={(pagination) => fetchGoals1(pagination.current, pagination.pageSize)}
        style={{ marginBottom: '20px' }}
      />
      <Button type="primary" onClick={() => {
        setIsEdit(false);
        setIsModalVisible(true);
      }}>Add New Goal</Button>
      <Modal
        title={isEdit ? 'Edit Goal' : 'Add New Goal'}
        visible={isModalVisible}
        onOk={handleOk1}
        onCancel={handleCancel1}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={isEdit ? updateGoal1 : addGoal1}
        >
          <Form.Item
            name="goalName"
            label="Goal Name"
            rules={[{ required: true, message: 'Please select a goal name' }]}
          >
            <Select placeholder="Select a goal">
              {goalOptions.map((goal) => (
                <Select.Option key={goal.value} value={goal.value}>
                  {goal.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="targetDate"
            label="Target Date"
            rules={[{ required: true, message: 'Please select a target date' }]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item
            name="amountRequired"
            label="Amount Required"
            rules={[{ required: true, message: 'Please enter the amount required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="remarks"
            label="Remarks"
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ClientGoals;
