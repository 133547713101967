// import React, { useEffect, useState } from 'react';
// import { Table, Button, Modal, Form, Input, Space, Popconfirm } from 'antd';
// import { getGoals, addGoal, updateGoal, deleteGoal } from './apiservices'; // Assume these functions are defined in apiservices.js

// const Mgoal= () => {
//   const [goals, setGoals] = useState([]);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [editingGoal, setEditingGoal] = useState(null);

//   useEffect(() => {
//     fetchGoals();
//   }, []);

//   const fetchGoals = async () => {
//     const response = await getGoals();
//     if (response.success) {
//       setGoals(response.data);
//     }
//   };

//   const handleAdd = () => {
//     setEditingGoal(null);
//     setIsModalVisible(true);
//   };

//   const handleEdit = (goal) => {
//     setEditingGoal(goal);
//     setIsModalVisible(true);
//   };

//   const handleDelete = async (goalId) => {
//     const response = await deleteGoal(goalId);
//     if (response.success) {
//       fetchGoals();
//     }
//   };

//   const handleSave = async (values) => {
//     if (editingGoal) {
//       // Update goal
//       const response = await updateGoal(editingGoal.id, values);
//       if (response.success) {
//         fetchGoals();
//       }
//     } else {
//       // Add new goal
//       const response = await addGoal(values);
//       if (response.success) {
//         fetchGoals();
//       }
//     }
//     setIsModalVisible(false);
//   };

//   const columns = [
//     { title: 'Goal ID', dataIndex: 'id', key: 'id' },
//     { title: 'Goal Name', dataIndex: 'name', key: 'name' },
//     { title: 'Description', dataIndex: 'description', key: 'description' },
//     {
//       title: 'Action',
//       key: 'action',
//       render: (text, record) => (
//         <Space size="middle">
//           <Button onClick={() => handleEdit(record)}>Edit</Button>
//           <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
//             <Button type="danger">Delete</Button>
//           </Popconfirm>
//         </Space>
//       ),
//     },
//   ];

//   return (
//     <div>
//       <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
//         Add Goal
//       </Button>
//       <Table columns={columns} dataSource={goals} rowKey="id" />
//       <Modal
//         title={editingGoal ? 'Edit Goal' : 'Add Goal'}
//         visible={isModalVisible}
//         onCancel={() => setIsModalVisible(false)}
//         footer={null}
//       >
//         <GoalForm
//           initialValues={editingGoal || { name: '', description: '' }}
//           onSave={handleSave}
//         />
//       </Modal>
//     </div>
//   );
// };

// const GoalForm = ({ initialValues, onSave }) => {
//   const [form] = Form.useForm();

//   const onFinish = (values) => {
//     onSave(values);
//   };

//   return (
//     <Form form={form} initialValues={initialValues} onFinish={onFinish} layout="vertical">
//       <Form.Item name="name" label="Goal Name" rules={[{ required: true, message: 'Please input the goal name!' }]}>
//         <Input />
//       </Form.Item>
//       <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please input the description!' }]}>
//         <Input />
//       </Form.Item>
//       <Form.Item>
//         <Button type="primary" htmlType="submit">
//           Save
//         </Button>
//       </Form.Item>
//     </Form>
//   );
// };

// // export default Mgoal;

// import React, { useEffect, useState } from 'react';
// import { Table, Button, Modal, Form, Input, Space, Popconfirm, message } from 'antd';
// import { getGoals, addGoal, updateGoal, deleteGoal } from './apiservices'; // Assume these functions are defined in apiservices.js

// const GoalTable = () => {
//   const [goals, setGoals] = useState([]);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [editingGoal, setEditingGoal] = useState(null);

//   useEffect(() => {
//     fetchGoals();
//   }, []);

//   const fetchGoals = async () => {
//     try {
//       const response = await getGoals();
//       if (response && response.success) {
//         setGoals(response.data);
//       } else {
//         message.error('Failed to fetch goals');
//       }
//     } catch (error) {
//       console.error('Error fetching goals:', error);
//       message.error('Error fetching goals');
//     }
//   };

//   const handleAdd = () => {
//     setEditingGoal(null);
//     setIsModalVisible(true);
//   };

//   const handleEdit = (goal) => {
//     setEditingGoal(goal);
//     setIsModalVisible(true);
//   };

//   const handleDelete = async (goalId) => {
//     try {
//       const response = await deleteGoal(goalId);
//       if (response && response.success) {
//         fetchGoals();
//         message.success('Goal deleted successfully');
//       } else {
//         message.error('Failed to delete goal');
//       }
//     } catch (error) {
//       console.error('Error deleting goal:', error);
//       message.error('Error deleting goal');
//     }
//   };

//   const handleSave = async (values) => {
//     try {
//       let response;
//       if (editingGoal) {
//         // Update goal
//         response = await updateGoal(editingGoal.id, values);
//       } else {
//         // Add new goal
//         response = await addGoal(values);
//       }

//       if (response && response.success) {
//         fetchGoals();
//         setIsModalVisible(false);
//         message.success('Goal saved successfully');
//       } else {
//         message.error('Failed to save goal');
//       }
//     } catch (error) {
//       console.error('Error saving goal:', error);
//       message.error('Error saving goal');
//     }
//   };

//   const columns = [
//     { title: 'Goal ID', dataIndex: 'id', key: 'id' },
//     { title: 'Goal Name', dataIndex: 'name', key: 'name' },
//     { title: 'Description', dataIndex: 'description', key: 'description' },
//     {
//       title: 'Action',
//       key: 'action',
//       render: (text, record) => (
//         <Space size="middle">
//           <Button onClick={() => handleEdit(record)}>Edit</Button>
//           <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
//             <Button type="danger">Delete</Button>
//           </Popconfirm>
//         </Space>
//       ),
//     },
//   ];

//   return (
//     <div>
//       <Table columns={columns} dataSource={goals} rowKey="id" />
//       <Button type="primary" onClick={handleAdd} style={{ marginTop: 16 }}>
//         Add Goal
//       </Button>
//       <Modal
//         title={editingGoal ? 'Edit Goal' : 'Add Goal'}
//         visible={isModalVisible}
//         onCancel={() => setIsModalVisible(false)}
//         footer={null}
//       >
//         <GoalForm
//           initialValues={editingGoal || { name: '', description: '' }}
//           onSave={handleSave}
//         />
//       </Modal>
//     </div>
//   );
// };

// const GoalForm = ({ initialValues, onSave }) => {
//   const [form] = Form.useForm();

//   const onFinish = (values) => {
//     onSave(values);
//   };

//   return (
//     <Form form={form} initialValues={initialValues} onFinish={onFinish} layout="vertical">
//       <Form.Item name="name" label="Goal Name" rules={[{ required: true, message: 'Please input the goal name!' }]}>
//         <Input />
//       </Form.Item>
//       <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please input the description!' }]}>
//         <Input />
//       </Form.Item>
//       <Form.Item>
//         <Button type="primary" htmlType="submit">
//           Save
//         </Button>
//       </Form.Item>
//     </Form>
//   );
// };

// export default GoalTable;

// import React, {useEffect, useState } from 'react';
// import { Table, Button, Modal, Form, Input, Space, Popconfirm, message } from 'antd';
// //import { getGoals, addGoal, updateGoal, deleteGoal } from './apiservices'; // Assume these functions are defined in apiservices.js

// const GoalTable = () => {
//   const [goals, setGoals] = useState([]);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [editingGoal, setEditingGoal] = useState(null);

//   useEffect(() => {
//     fetchGoals();
//   }, []);

//   const fetchGoals = async () => {
//     try {
//       const response = await fetch('http://localhost:5000/api/goals', {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
//       const data = await response.json();
//       if (data.success) {
//         setGoals(data.data);
//       }
//     } catch (error) {
//       console.error('Error fetching goals:', error);
//     }
//   };
//   const handleAdd = () => {
//     setEditingGoal(null);
//     setIsModalVisible(true);
//   };

//   const handleEdit = (goal) => {
//     setEditingGoal(goal);
//     setIsModalVisible(true);
//   };

//   const handleDelete = async (goalId) => {
//     try {
//       const response = await deleteGoal(goalId);
//       if (response && response.success) {
//         fetchGoals();
//         message.success('Goal deleted successfully');
//       } else {
//         message.error('Failed to delete goal');
//       }
//     } catch (error) {
//       console.error('Error deleting goal:', error);
//       message.error('Error deleting goal');
//     }
//   };

//   const handleSave = async (values) => {
//     try {
//       let response;
//       if (editingGoal) {
//         // Update goal
//         response = await updateGoal(editingGoal.id, values);
//       } else {
//         // Add new goal
//         response = await addGoal(values);
//       }
  
//       if (response && response.success) {
//         // Update the goals state with the updated data
//         const updatedGoals = editingGoal
//           ? goals.map((goal) => (goal.id === editingGoal.id ? { ...goal, ...values } : goal))
//           : [...goals, { ...values, id: response.data.id }];
//         setGoals(updatedGoals);
  
//         setIsModalVisible(false);
//         message.success('Goal saved successfully');
//       } else {
//         message.error('Failed to save goal');
//       }
//     } catch (error) {
//       console.error('Error saving goal:', error);
//       message.error('Error saving goal');
//     }
//   };
  

//   const columns = [
//     { title: 'Goal ID', dataIndex: 'id', key: 'id' },
//     { title: 'Goal Name', dataIndex: 'name', key: 'name' },
//     { title: 'Description', dataIndex: 'description', key: 'description' },
//     {
//       title: 'Action',
//       key: 'action',
//       render: (text, record) => (
//         <Space size="middle">
//           <Button onClick={() => handleEdit(record)}>Edit</Button>
//           <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
//             <Button type="danger">Delete</Button>
//           </Popconfirm>
//         </Space>
//       ),
//     },
//   ];

//   return (
//     <div>
//       <Table columns={columns} dataSource={goals} rowKey="id" />
//       <div style={{ textAlign: 'center', marginTop: 16 }}>
//         <Button type="primary" onClick={handleAdd}>
//           Add Goal
//         </Button>
//       </div>
//       <Modal
//         title={editingGoal ? 'Edit Goal' : 'Add Goal'}
//         visible={isModalVisible}
//         onCancel={() => setIsModalVisible(false)}
//         footer={null}
//       >
//         <GoalForm
//           initialValues={editingGoal || { name: '', description: '' }}
//           onSave={handleSave}
//         />
//       </Modal>
//     </div>
//   );
// };

// const GoalForm = ({ initialValues, onSave }) => {
//   const [form] = Form.useForm();

//   const onFinish = (values) => {
//     onSave(values);
//   };

//   return (
//     <Form form={form} initialValues={initialValues} onFinish={onFinish} layout="vertical">
//       <Form.Item name="name" label="Goal Name" rules={[{ required: true, message: 'Please input the goal name!' }]}>
//         <Input />
//       </Form.Item>
//       <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please input the description!' }]}>
//         <Input />
//       </Form.Item>
//       <Form.Item>
//         <Button type="primary" htmlType="submit">
//           Save
//         </Button>
//       </Form.Item>
//     </Form>
//   );
// };

// export default GoalTable;


import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Space, Popconfirm, message } from 'antd';

const Mgoal = () => {
    const [goals, setGoals] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingGoal, setEditingGoal] = useState(null);
    const [form] = Form.useForm();
  
    useEffect(() => {
      fetchGoals();
    }, []);
  
    const fetchGoals = async () => {
      try {
        const response = await fetch(`http://localhost/goal/api/getGoals`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (data.success) {
          // Assigning serial numbers sequentially
          const goalsWithSerialNumbers = data.data.map((goal, index) => ({
            ...goal,
            serialNumber: index + 1,
          }));
          setGoals(goalsWithSerialNumbers);
        } else {
          message.error('Failed to fetch goals');
        }
      } catch (error) {
        message.error('Error fetching goals');
      }
    };
  
    const addGoal = async (values) => {
      const action='addGoal';
      try {
        const response = await fetch('http://localhost/goal/api/userdata.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({action, ...values }),
        });
        const data = await response.json();
        console.log("data=",data)
        if (data.success) {
          // Adding the new goal with a serial number based on the current length of goals array
          setGoals([...goals, { ...data.data, serialNumber: goals.length + 1 }]);
          form.resetFields();
          setIsModalVisible(false);
          message.success('Goal added successfully');
        } else {
          message.error('Failed to add goal 2');
        }
      } catch (error) {
        console.error('Error adding goal:', error);
        message.error('Failed to add goal 3');
      }
    };
  
    // const updateGoal = async (values) => {
    // const action = 'updateGoal'; // Define the action
    //   try {
    //     const response = await fetch(`http://localhost/goal/api/userdata.php/${editingGoal._id}`, {
    //       method: 'PUT',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify({ action, ...values, id: editingGoal._id }), 
    //     });
    //     const data = await response.json();
    //     if (data.success) {
    //       setGoals(goals.map(goal => goal._id === editingGoal._id ? data.data : goal));
    //       form.resetFields();
    //       setIsModalVisible(false);
    //       setEditingGoal(null);
    //       message.success('Goal updated successfully');
    //     }
    //   } catch (error) {
    //     console.error('Error updating goal:', error);
    //     message.error('Failed to update goal');
    //   }
    // };

  //   const updateGoal = async (values) => {
  //     const action = 'updateGoal'; // Define the action
  
  //     try {
  //         const response = await fetch('http://localhost/goal/api/userdata.php', {
  //             method: 'PUT',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //             },
  //             body: JSON.stringify({ action, ...editingGoal, ...values }),
  //         });
  
  //         if (!response.ok) {
  //             throw new Error(`HTTP error! status: ${response.status}`);
  //         }
  
  //         const data = await response.json();
  //         if (data.success) {
  //             setGoals(goals.map(goal => goal.id === editingGoal.id ? data.data : goal));
  //             form.resetFields();
  //             setIsModalVisible(false);
  //             setEditingGoal(null);
  //             message.success('Goal updated successfully');
  //         } else {
  //             message.error('Failed to update goal: ' + (data.message || 'Unknown error'));
  //         }
  //     } catch (error) {
  //         console.error('Error updating goal:', error);
  //         message.error('Failed to update goal');
  //     }
  // };

  const updateGoal = async (values) => {
    const action = 'updateGoal'; // Define the action

    try {
        const response = await fetch('http://localhost/goal/api/userdata.php', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ action, ...editingGoal, ...values }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.success) {
            // Retain the serial number in the updated goal
            const updatedGoal = {
                ...editingGoal,
                ...data.data,
            };

            setGoals(goals.map(goal => goal.id === editingGoal.id ? updatedGoal : goal));
            form.resetFields();
            setIsModalVisible(false);
            setEditingGoal(null);
            message.success('Goal updated successfully');
        } else {
            message.error('Failed to update goal: ' + (data.message || 'Unknown error'));
        }
    } catch (error) {
        console.error('Error updating goal:', error);
        message.error('Failed to update goal');
    }
};

  
  
    // const handleDelete = async (id) => {
    //   const action = 'deleteGoal'; // Define the action
    //   try {
    //     const response = await fetch(`http://localhost/goal/api/userdata.php`, {
    //       method: 'DELETE',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify({ action, id }),
    //     });
        
    //     if (response.ok) {
    //       setGoals(goals.filter(goal => goal._id !== id));

    //       // Recalculate serial numbers
    //       const updatedGoals = goals.filter(goal => goal._id !== id).map((goal, index) => ({
    //         ...goal,
    //         serialNumber: index + 1,
    //       }));
    //       setGoals(updatedGoals);

    //       message.success('Goal deleted successfully');
    //     }
    //   } catch (error) {
    //     console.error('Error deleting goal:', error);
    //     message.error('Failed to delete goal');
    //   }
    // };

    const handleDelete = async (name) => {
      const action = 'deleteGoal'; // Define the action
  
      try {
          const response = await fetch(`http://localhost/goal/api/userdata.php`, {
              method: 'DELETE',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ action, name }), // Send the goal name instead of ID
          });
  
          if (response.ok) {
              const data = await response.json();
  
              if (data.success) {
                  // Update the frontend state by filtering out the deleted goal by name
                  const updatedGoals = goals
                      .filter(goal => goal.name !== name)
                      .map((goal, index) => ({
                          ...goal,
                          serialNumber: index + 1,
                      }));
  
                  setGoals(updatedGoals);
                  message.success('Goal deleted successfully');
              } else {
                  message.error('Failed to delete goal: ' + (data.message || 'Unknown error'));
              }
          } else {
              message.error(`HTTP error! status: ${response.status}`);
          }
      } catch (error) {
          console.error('Error deleting goal:', error);
          message.error('Failed to delete goal');
      }
  };
  
  
  
  
    const handleEdit = (record) => {
      form.setFieldsValue({
        ...record,
      });
      setEditingGoal(record);
      setIsModalVisible(true);
    };
  
    const handleOk = () => {
      form.submit();
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
      form.resetFields();
      setEditingGoal(null);
    };
  
    const handleSave = (values) => {
      if (editingGoal) {
        updateGoal(values);
      } else {
        addGoal(values);
      }
    };
  
    const columns = [
      { title: 'Serial Number', dataIndex: 'serialNumber', key: 'serialNumber' },
      { title: 'Goal Name', dataIndex: 'name', key: 'name' },
      { title: 'Description', dataIndex: 'description', key: 'description' },
      {
        title: 'Actions',
        key: 'actions',
        render: (text, record) => (
          <Space size="middle">
            <Button style={{ width: '60px' }} onClick={() => handleEdit(record)}>Edit</Button>
            <Popconfirm
              title="Are you sure delete this goal?"
              onConfirm={() => handleDelete(record.name)}
              okText="Yes"
              cancelText="No"
            >
              <Button style={{ width: '60px' }} danger>Delete</Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];

  return (
    <div>
      <Table dataSource={goals} columns={columns} pagination={{ pageSize: 5 }} rowKey="_id" />
      <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ marginTop: 16 ,width:'110px',marginLeft:'170px'}}>
        Add Goal
      </Button>
      <Modal
        title={editingGoal ? 'Edit Goal' : 'Add New Goal'}
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        footer={null}
      >
        <GoalForm initialValues={editingGoal || { name: '', description: '' }} onSave={handleSave} />
      </Modal>
    </div>
  );
};

const GoalForm = ({ initialValues, onSave }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSave(values);
  };


  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);


  return (
    <Form form={form} initialValues={initialValues} onFinish={onFinish} layout="vertical">
      <Form.Item name="name" label="Goal Name" rules={[{ required: true, message: 'Please input the goal name!' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please input the description!' }]}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Mgoal;
