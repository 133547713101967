
//ClientsTable.jsx
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, DatePicker, Select, Popconfirm, message } from 'antd';
import moment from 'moment';
import './cltable.css';

const ClientsTable = () => {
  const [clients, setClients] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingClient, setEditingClient] = useState(null);
  const [form] = Form.useForm();


const apiUrl = process.env.REACT_APP_API_URL;
 console.log("API in clientstable=",apiUrl);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {

    try {
      const response = await fetch(`${apiUrl}/getClients.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Fetched data:', data);
      if (data.success) {
        const formattedClients = data.data.map(client => ({
          ...client,
          dob: moment(client.dob).format('YYYY-MM-DD'),
        }));
        setClients(formattedClients);
      } else {
        message.error(data.message || 'Failed to fetch clients');
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
      message.error('Failed to connect to the server');
    }
  };
  


  //new one
  const addClient = async (values) => {
    console.log('Form values:', values);  // Log the form values
  
    const newClient = {
      action: "addClient",
      ...values,
      dob: values.dob ? values.dob.format('YYYY-MM-DD') : null,
    };
  
    console.log('Data being sent to server:', newClient);  // Log the data being sent to server
  
    try {
      const response = await fetch(`${apiUrl}/userdata.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newClient),
      });
      const data = await response.json();
      if (response.ok) {
        setClients([...clients, {
          ...data.data,
          dob: moment(data.data.dob).format('YYYY-MM-DD'),
        }]);
        form.resetFields();
        setIsModalVisible(false);
        message.success('Client added successfully');
      } else {
        message.error(data.error || 'Failed to add client');
      }
    } catch (error) {
      console.error('Error adding client:', error);
    }
  };


  const updateClient = async (values) => {
  
    const updatedClient = {
      action: "updateClient",
        ...editingClient,
        ...values,
        dob: values.dob.format('YYYY-MM-DD'),
    };

    try {
        const response = await fetch(`${apiUrl}/userdata.php`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedClient),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.success) {
            setClients(clients.map(client => client.email === editingClient.email ? {
                ...data.data,
                dob: moment(data.data.dob).format('YYYY-MM-DD') } : client));

            form.resetFields();
            setIsModalVisible(false);
            setEditingClient(null);
            message.success('Client updated successfully');
        } else {
            message.error('Failed to update client: ' + data.message);
        }
    } catch (error) {
        console.error('Error updating client:', error);

    }
};


  // const handleDelete = async (id) => {
  //   try {
  //     const response = await fetch(`${apiUrl}/userdata.php/${id}`, {
  //       method: 'DELETE',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     });
  //     if (response.ok) {
  //       setClients(clients.filter(client => client._id !== id));
  //       message.success('Client deleted successfully');
  //     } else {
  //       message.error('Failed to delete client');
  //     }
  //   } catch (error) {
  //     console.error('Error deleting client:', error);
  //   }
  // };

const handleDelete = async (email) => {
  try {
      const response = await fetch(`${apiUrl}/userdata.php`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ action: 'deleteClient', email }), // Include action in the payload
      });

      if (response.ok) {
          setClients(clients.filter(client => client.email !== email)); // Filter out the deleted client
          message.success('Client deleted successfully');
      } else {
          const errorData = await response.json();
          message.error('Failed to delete client: ' + (errorData.message || 'Unknown error'));
      }
  } catch (error) {
      console.error('Error deleting client:', error);
      message.error('An error occurred while deleting the client.');
  }
};



  const handleEdit = (record) => {
    form.setFieldsValue({
      ...record,
      dob: moment(record.dob),
    });
    setEditingClient(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditingClient(null);
  };

  const columns = [
    { title: 'Sl No', dataIndex: 'slNo', key: 'slNo' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'DOB', dataIndex: 'dob', key: 'dob' },
    { title: 'City', dataIndex: 'city', key: 'city' },
    { title: 'PAN', dataIndex: 'pan', key: 'pan' },
    { title: 'Phone', dataIndex: 'phone', key: 'phone' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Income Group', dataIndex: 'incomeGroup', key: 'incomeGroup' },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div>
          <Button className="action-button1" onClick={() => handleEdit(record)}>Edit</Button>
          <Popconfirm
            title="Are you sure delete this client?"
            onConfirm={() => handleDelete(record.email)} // Pass email here
            okText="Yes"
            cancelText="No"
            className="custom-popconfirm"
          >
            <Button className="action-button1" danger>Delete</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={clients ? clients.map((client, index) => ({ ...client, slNo: index + 1 })): []}
        rowKey="_id"
        pagination={{ pageSize: 5 }}
      />
      <Button type="primary" onClick={() => setIsModalVisible(true)} className="action-button" style={{ marginTop: 16 }}>
        Add Client
      </Button>
      <Modal
        title={editingClient ? 'Edit Client' : 'Add New Client'}
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <Form
          form={form}
          onFinish={editingClient ? updateClient : addClient}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please input the client name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="dob"
            label="Date of Birth"
            rules={[{ required: true, message: 'Please select the date of birth!' }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="city"
            label="City"
            rules={[{ required: true, message: 'Please input the city!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="pan"
            label="PAN"
            rules={[{ message: 'Please input the PAN!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone"
            rules={[{ required: true, message: 'Please input the phone number!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="incomeGroup"
            label="Income Group"
            rules={[{ required: true, message: 'Please select the income group!' }]}
          >
            <Select>
              <Select.Option value="1-5L">1-5L</Select.Option>
              <Select.Option value="5-10L">5-10L</Select.Option>
              <Select.Option value="10-50L">10-50L</Select.Option>
              <Select.Option value="50-1Cr">50-1Cr</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ClientsTable;
