

// apiservices.js
import axios from 'axios';
axios.defaults.withCredentials=true;
const apiUrl = process.env.REACT_APP_API_URL;
const API_URL=process.env.REACT_APP_VAR;

export const signup = async (data) => {
    try {
        const response = await fetch(`${apiUrl}/userdata.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json();
    } catch (error) {
        console.error('Signup error:', error);
        return { success: false, message: 'Network error' };
    }
};

 export const login = async (credentials) => {
    try {
      console.log("api in apiservices.js=",API_URL);
        const response = await axios.post(`${apiUrl}/userdata.php`,  credentials );
        return response.data;
    } catch (error) {
        if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            return { success: false, message: error.response.data.message };
        } else if (error.request) {
            console.error('No response received:', error.request);
            return { success: false, message: 'No response received from the server' };
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error setting up the request:', error.message);
            return { success: false, message: 'Error setting up the request' };
        }
    }
};


// Function to get user data
export const getUser = async () => {
    try {
      const response = await axios.post(`${apiUrl}/userdata.php`, { action: 'checkSession' }, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return { success: false, message: 'Failed to fetch user data' };
    }
  };

  // Function to log out the user


export const updateUser = async (formData) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
  
      const response = await axios.put(`${apiUrl}/userdata.php`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json', // If you're sending JSON data
        },
      });
  
      return response.data;
    } catch (error) {
      return handleAxiosError(error);
    }
  };


//changepassword

export const changePassword = async (data) => {
  try {
      const response = await axios.post(`${apiUrl}/userdata.php`, data, { withCredentials: true });
      return response.data;
  } catch (error) {
      console.error('Change password error:', error);
      return { success: false, message: 'Network error' };
  }
};

export const checkSession = async () => {
  try {
      const response = await axios.post(`${apiUrl}/userdata.php`, { action: 'checkSession' }, { withCredentials: true });
      return response.data;
  } catch (error) {
      console.error('Session check error:', error);
      return { success: false, message: 'Network error' };
  }
};


// Function to log out the user
export const logout = async () => {
    try {
      const response = await axios.post('http://localhost/goal/api/userdata.php', { action: 'logout' }, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error('Logout error:', error);
      return { success: false, message: 'Failed to log out' };
    }
  };
  
const handleAxiosError = (error) => {
    if (error.response) {
        return { success: false, message: error.response.data.message };
    } else if (error.request) {
        return { success: false, message: 'No response received from the server' };
    } else {
        return { success: false, message: 'Error setting up the request' };
    }
};

















































