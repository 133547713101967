
// import React from 'react';
// import { Menu } from 'antd';
// //import { getUser,logout } from './apiservices';
// //import { UserOutlined, DownOutlined } from '@ant-design/icons';
// import { Route, Routes, useNavigate } from 'react-router-dom';
// import ClientsTable from './Clientstable';
// import ClientGoals from './Clientsgoals';
// //
// import AssignFunds from './Assignfunds';
// import Changepassword from './changepassword';
// import Mgoal from './mgoal';
// import Calculator from './calculator';
// //import { Link } from 'react-router-dom';, Dropdown, Button  { useEffect, useState }
// import './clientDash.css';
// import MFundHouse from './mfundhouse';

//  function Clientdashboard() {
// //   const [username, setUsername] = useState('');
//  const navigate = useNavigate();

// //   useEffect(() => {
// //     getUser().then(response => {
// //       if (response.success) {
// //         setUsername(response.data.username);
// //       }
// //     });
// //   }, []);

//   // const handleMenuClick = async ({ key }) => {
//   //   if (key === "logout") {
//   //     try {
//   //       const response = await logout(); // Call the logout function and await its response
//   //       if (response.success) {
//   //         // Navigate to login page after successful logout
//   //         navigate('/login');
//   //       } else {
//   //         // Handle the error case (e.g., show an error message)
//   //         console.error('Failed to log out');
//   //       }
//   //     } catch (error) {
//   //       console.error('Logout error:', error);
//   //     }
//   //   } else if (key === "profile") {
//   //     navigate('/clientDashBoard/profile');
//   //   } else {
//   //     navigate(key);
//   //   }
//   // };
  

//   // const userMenu = (
//   //   <Menu onClick={handleMenuClick}>
//   //     <Menu.Item key="profile">Profile</Menu.Item>
//   //     <Menu.Item key="logout">Logout</Menu.Item>
//   //   </Menu>
//   // );

//   const handleMenuClick = ({ key }) => {
//     navigate(key);
//   };
//   return (  
//     // <div >
//     //      <div className="header" style={{height:'70px',display: 'flex',justifyContent: 'space-between'}}>
//     //      <div className="logo" style={{marginRight:'998px'}}> 
//     //       <img src="assests/logo1.jpg" alt="Logo" style={{height: '70px',width:'70px',borderRadius:'50%',objectFit:'cover'}} /> {/* Update the path and styling as needed */}
//     //      </div>
//     //      <nav className="nav" style={{ display: 'flex ',marginRight:'20px' }}>
//     //       <Link to="/login">Login/Signup</Link>
//     //     </nav>
//     //     <Dropdown overlay={userMenu} trigger={['click']}>
//     //       <Button className="user-button" style={{width:'150px'}}>
//     //         <UserOutlined style={{ marginRight: 8 }} />
//     //         {username || "User"} <DownOutlined />
//     //       </Button>
//     //     </Dropdown>
//     //     </div>
      
//     <div className="display">
//     <div style={{ display: "flex", flexDirection: "row" }}>  

//       <Menu className='labels'
//         onClick={handleMenuClick}
//         defaultSelectedKeys={[window.location.pathname]}
//         mode="inline"
      
//       style={{ height: '100%', borderRight: 0 }}>

//   <Menu.Item key="/clientDashBoard/" className="menu-label">Dashboard</Menu.Item>
//   <Menu.Item key="/clientDashBoard/Clientstable" className="menu-label">Clients</Menu.Item>
//   <Menu.Item key="/clientDashBoard/Clientsgoals" className="menu-label">Client's Goal</Menu.Item>
//   <Menu.Item key="/clientDashBoard/Assignfunds" className="menu-label">Assign Funds</Menu.Item>
//   <Menu.SubMenu key="masterData" title="Master Data" className="menu-item-master-data">
//     <Menu.Item key="/clientDashBoard/mgoal" className="menu-item-sub">Goal</Menu.Item>
//     <Menu.Item key="/clientDashBoard/mfundhouse" className="menu-item-sub">Fund House</Menu.Item>
//     <Menu.Item key="/clientDashBoard/mutualfunds" className="menu-item-sub">Mutual Funds</Menu.Item>
//     <Menu.Item key="/clientDashBoard/updatenav" className="menu-item-sub">Update NAV</Menu.Item>
//   </Menu.SubMenu>
//   <Menu.Item key="/clientDashBoard/portfolio" className="menu-label">Portfolio</Menu.Item>
//   <Menu.Item key="/clientDashBoard/changepassword" className="menu-label">Change password</Menu.Item>
//   <Menu.SubMenu key="calculator" title="Calculator" className="menu-item-calculator">
//     <Menu.Item key="/clientDashBoard/childeducation" className="menu-item-sub">Child Education</Menu.Item>
//     <Menu.Item key="/clientDashBoard/retirement" className="menu-item-sub">Retirement Planning</Menu.Item>
//     <Menu.Item key="/clientDashBoard/buyinghome" className="menu-item-sub">Buying Home</Menu.Item>
//     <Menu.Item key="/clientDashBoard/childmarriage" className="menu-item-sub">Child Marriage</Menu.Item>
//     <Menu.Item key="/clientDashBoard/wealthcreation" className="menu-item-sub">Wealth Creation</Menu.Item>
//     <Menu.Item key="/clientDashBoard/vacation" className="menu-item-sub">Vacation</Menu.Item>
//   </Menu.SubMenu>
// </Menu>
//       <Content1 />
//       </div>
//       </div> 
// // </div> 
//   );
// }

// function Content1() {
//   return (
//     <div style={{ marginLeft: 80, padding: 20 }}>
//       <Routes>
//         <Route path='/' element={<div>welcome to Dashboard</div>} />
//         <Route path='/Clientstable' element={<ClientsTable />} />
//         <Route path='/Clientsgoals' element={<ClientGoals/>} />
//         <Route path='/Assignfunds' element={<AssignFunds/>} />
//         <Route path='/masterdata' element={<div>welcome to MasterData</div>} />
//         <Route path='/portfolio' element={<div>welcome to Portfolio</div>} />
//         {/* <Route path='/profile' element={<Profile/>} />  */}
//         <Route path='/changepassword' element={<Changepassword/>} />
//         <Route path='/mgoal' element={<Mgoal/>}/>
//         <Route path='/calculator' element={<Calculator/>}/>
//         <Route path='/mfundhouse' element={<MFundHouse/>}/>
//       </Routes>
//     </div>
//   );
// }

// export default Clientdashboard;






// clientDashBoard.js
import React from 'react';
import { Menu } from 'antd';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ClientsTable from './clientstable';
import ClientGoals from './clientsgoals';
import AssignFunds from './assignfunds';
//import Changepassword from './changepassword';
import Mgoal from './mgoal';
import Calculator from './calculator';
import MFundHouse from './mfundhouse';
import MFund from './mmutual';
import Dashboard from './dashboard';
import Calchild from './calchild';
import Retirement from './calreti';
import Homeloan from './calhome';
import WealthCreation from './calwealth';
import Vacation from './calvacation';
import './clientDash.css';
import Childmarriage from './calchildmar';


function Clientdashboard() {
  const navigate = useNavigate();

  const handleMenuClick = ({ key }) => {
    navigate(key);
  };

  return (
    <div className="display">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Menu
          className='labels'
          onClick={handleMenuClick}
          defaultSelectedKeys={[window.location.pathname]}
          mode="inline"
          style={{ height: '100%', borderRight: 0 }}
        >
          <Menu.Item key="/clientDashBoard/" className="menu-label">Dashboard</Menu.Item>
          <Menu.Item key="/clientDashBoard/clientstable" className="menu-label">Clients</Menu.Item>
          <Menu.Item key="/clientDashBoard/clientsgoals" className="menu-label">Client's Goal</Menu.Item>
          <Menu.Item key="/clientDashBoard/assignfunds" className="menu-label">Assign Funds</Menu.Item>
          <Menu.SubMenu key="masterData" title="Master Data" className="menu-item-master-data">
            <Menu.Item key="/clientDashBoard/mgoal" className="menu-item-sub">Goal</Menu.Item>
            <Menu.Item key="/clientDashBoard/mfundhouse" className="menu-item-sub">Fund House</Menu.Item>
            <Menu.Item key="/clientDashBoard/mmutual" className="menu-item-sub">Mutual Funds</Menu.Item>
            <Menu.Item key="/clientDashBoard/updatenav" className="menu-item-sub">Update NAV</Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="/clientDashBoard/portfolio" className="menu-label">Portfolio</Menu.Item>
          {/* <Menu.Item key="/clientDashBoard/changepassword" className="menu-label">Change password</Menu.Item> */}
          <Menu.SubMenu key="calculator" title="Calculator" className="menu-item-calculator">
            <Menu.Item key="/clientDashBoard/calchild" className="menu-item-sub">Child Education</Menu.Item>
            <Menu.Item key="/clientDashBoard/calreti" className="menu-item-sub">Retirement Planning</Menu.Item>
            <Menu.Item key="/clientDashBoard/calhome" className="menu-item-sub">Buying Home</Menu.Item>
            <Menu.Item key="/clientDashBoard/calchildmar" className="menu-item-sub">Child Marriage</Menu.Item>
            <Menu.Item key="/clientDashBoard/calwealth" className="menu-item-sub">Wealth Creation</Menu.Item>
            <Menu.Item key="/clientDashBoard/calvacation" className="menu-item-sub">Vacation</Menu.Item>
          </Menu.SubMenu>
        </Menu>
        <Content1 />
      </div>
    </div>
  );
}
//style={{ marginLeft: 80, padding: 20 }}
function Content1() {
  return (
    <div style={{ marginLeft: 80, padding: 20 }}>
      <Routes>
        <Route path='/' element={<Dashboard/>} />
        <Route path='/clientstable' element={<ClientsTable />} />
        <Route path='/clientsgoals' element={<ClientGoals />} />
        <Route path='/assignfunds' element={<AssignFunds />} />
        <Route path='/portfolio' element={<div>Welcome to Portfolio</div>} />
        {/* <Route path='/changepassword' element={<Changepassword />} /> */}
        <Route path='/mgoal' element={<Mgoal />} />
        <Route path='/calculator' element={<Calculator />} />
        <Route path='/mfundhouse' element={<MFundHouse />} />
        <Route path='/mmutual' element={<MFund />} />
        <Route path='/calchild' element={<Calchild/>}/>
        <Route path='/calreti' element={<Retirement/>}/>
        <Route path='/calhome' element={<Homeloan/>}/>
        <Route path='/calchildmar' element={<Childmarriage/>}/>
        <Route path='/calwealth' element={<WealthCreation/>}/>
        <Route path='/calvacation' element={<Vacation/>}/>
      </Routes>
    </div>
  );
}

export default Clientdashboard;
