import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import jsPDF from 'jspdf';

const Childmarriage= () => {
  const [name, setName] = useState('');
  const [childName, setChildName] = useState('');
  const [childAge, setChildAge] = useState(1);
  const [marriageAge, setMarriageAge] = useState('');
  const [weddingCost, setWeddingCost] = useState('');
  const [inflation, setInflation] = useState('');
  const [newInvestment, setNewInvestment] = useState(4);
  const [uploadedImage, setUploadedImage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [yearsToMarriage, setYearsToMarriage] = useState(0);
  const [futureValue, setFutureValue] = useState(0);
  const [lumpsumInvestment, setLumpsumInvestment] = useState(0);
  const [sipRequired, setSipRequired] = useState(0);

  const displayImage = (event) => {
    const reader = new FileReader();
    reader.onload = function() {
      setUploadedImage(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handlePrint = () => {
    const pdf = new jsPDF();

    const content = `
      Dear ${name}, Dream Wedding Planning

      Child Name: ${childName}
      Child age: ${childAge}
      Expected age of marriage: ${marriageAge}
      Current cost of wedding: ${weddingCost}
      Inflation (%): ${inflation}%
      Return on new investment (%): ${newInvestment}%

      Calculation based on above assumptions
      Future Value of Wedding Cost: ${futureValue.toFixed(0)}
      Lump Sum Investment Required to Achieve Goal: ${lumpsumInvestment.toFixed(0)}
      SIP Required to Achieve Goal: ${sipRequired.toFixed(0)}
    `;
    pdf.text(content, 10, 10);

    pdf.save('wedding_plan.pdf');
  };

  const handleCalculate = () => {
    const currentAgeInt = parseInt(childAge);
    const marriageAgeInt = parseInt(marriageAge);
    const currentCostFloat = parseFloat(weddingCost);
    const inflationRateFloat = parseFloat(inflation) / 100;
    const returnRateFloat = parseFloat(newInvestment) / 100;

    const yearsToMarriage = marriageAgeInt - currentAgeInt;
    setYearsToMarriage(yearsToMarriage);

    const futureVal = currentCostFloat * Math.pow(1 + inflationRateFloat, yearsToMarriage);
    setFutureValue(futureVal);

    const lumpsumInvest = futureVal / Math.pow(1 + returnRateFloat, yearsToMarriage);
    setLumpsumInvestment(lumpsumInvest);

    const monthlyReturnRate = Math.pow(1 + returnRateFloat, 1 / 12);
    const totalMonths = yearsToMarriage * 12;

    const sipReq = (futureVal * (monthlyReturnRate - 1)) / (Math.pow(monthlyReturnRate, totalMonths) - 1);
    setSipRequired(sipReq);

    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>Dream Wedding Calculator</h3>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 grid-margin stretch-card text-center" style={{ display: 'block' }}>
            <div className="card">
              <div className="card-body">
                <div className="image-upload">
                  <div className="content_img">
                    <input type="file" id="myFile" onChange={displayImage} />
                    <img id="uploadedImage" src={uploadedImage} alt="Uploaded" height="220px" width="210px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 grid-margin stretch-card" style={{ display: 'block' }}>
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <form id="weddingForm">
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="name" className="card-title">Name</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="name"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label htmlFor="childName" className="card-title">Child name</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="childName"
                                placeholder="Child name"
                                value={childName}
                                onChange={(e) => setChildName(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <div className="form-group">
                              <label htmlFor="childAge" className="card-title">Child age</label>
                              <div className="range-slider">
                                <input
                                  className="range-slider__range input-range"
                                  type="range"
                                  value={childAge}
                                  min="0"
                                  max="50"
                                  name="childAge"
                                  id="childAge"
                                  onChange={(e) => setChildAge(e.target.value)}
                                />
                                <input
                                  className="range-slider__value range-value"
                                  type="text"
                                  value={childAge}
                                  id="childAge-value"
                                  readOnly
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <div className="form-group">
                              <label htmlFor="marriageAge" className="card-title">Expected age of marriage</label>
                              <div className="range-slider">
                                <input
                                  className="range-slider__range input-range"
                                  type="range"
                                  value={marriageAge}
                                  min="18"
                                  max="50"
                                  name="marriageAge"
                                  id="marriageAge"
                                  onChange={(e) => setMarriageAge(e.target.value)}
                                />
                                <input
                                  className="range-slider__value range-value"
                                  type="text"
                                  value={marriageAge}
                                  id="marriageAge-value"
                                  readOnly
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <div className="form-group">
                              <label htmlFor="weddingCost" className="card-title">Current cost of wedding</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="weddingCost"
                                placeholder="Current cost of wedding"
                                value={weddingCost}
                                onChange={(e) => setWeddingCost(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="inflation" className="card-title">Inflation (%)</label>
                              <input
                                type="number"
                                className="form-control text-size"
                                id="inflation"
                                placeholder="Inflation rate"
                                value={inflation}
                                onChange={(e) => setInflation(e.target.value)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label htmlFor="newInvestment" className="card-title">Return on new investment (%)</label>
                              <input
                                type="number"
                                className="form-control text-size"
                                id="newInvestment"
                                placeholder="Return on new investment"
                                value={newInvestment}
                                onChange={(e) => setNewInvestment(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button type="button" className="btn btn-primary" onClick={handleCalculate}>Calculate</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Dear {name}<br /> Dream Wedding Planning</h5>
                <button type="button" className="close" onClick={closeModal} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <td>Child Name</td>
                        <td>{childName}</td>
                      </tr>
                      <tr>
                        <td>Child age</td>
                        <td>{childAge}</td>
                      </tr>
                      <tr>
                        <td>Expected age of marriage</td>
                        <td>{marriageAge}</td>
                      </tr>
                      <tr>
                        <td>Current cost of wedding</td>
                        <td>{weddingCost}</td>
                      </tr>
                      <tr>
                        <td>Inflation (%)</td>
                        <td>{inflation}%</td>
                      </tr>
                      <tr>
                        <td>Return on new investment (%)</td>
                        <td>{newInvestment}%</td>
                      </tr>
                      <tr>
                        <td>Calculation based on above assumptions</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Future Value of Wedding Cost</td>
                        <td>{futureValue.toFixed(0)}</td>
                      </tr>
                      <tr>
                        <td>Lump Sum Investment Required to Achieve Goal</td>
                        <td>{lumpsumInvestment.toFixed(0)}</td>
                      </tr>
                      <tr>
                        <td>SIP Required to Achieve Goal</td>
                        <td>{sipRequired.toFixed(0)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                <button type="button" className="btn btn-primary" onClick={handlePrint}>Download</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Childmarriage;
