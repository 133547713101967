// import React, { useState } from 'react';
// import { Button, Input, Upload, message } from 'antd';
// import { UploadOutlined } from '@ant-design/icons';
// import axios from 'axios';
// import './mfundhouse.css';

// const FundHouse = () => {
//   const [file, setFile] = useState(null);
//   const [remarks, setRemarks] = useState('');

//   const handleFileChange = (info) => {
//     // Extract the file from the info object and set it to state
//     const selectedFile = info.file.originFileObj;
//     if (selectedFile) {
//       setFile(selectedFile);
//       message.success(`${selectedFile.name} file selected successfully`);
//       console.log('Selected file:', selectedFile); // Debugging line
//     }
//   };

//   const handleUpload = async () => {
//     if (!file) {
//       message.error('Please choose a file to upload');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('remarks', remarks);

//     try {
//       const response = await axios.post('http://localhost:5000/api/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });
//       if (response.data.success) {
//         message.success('File uploaded successfully');
//         setFile(null);
//         setRemarks('');
//       }
//     } catch (error) {
//       message.error('File upload failed');
//     }
//   };

//   return (
//     <div className="upload-container">
//       <h1>Upload File</h1>
//       <p>Download Template File: <a id="templateLink" href="/fund_house_template.xls" download>Click here</a></p>
//       <div className="upload-section">
//         <label>Select File:</label>
//         <Upload
//           beforeUpload={() => false}
//           onChange={handleFileChange}
//         >
//           <Button icon={<UploadOutlined />}>Choose File</Button>
//         </Upload>
//       </div>
//       <div className="remarks-section">
//         <label>Remarks:</label>
//         <Input.TextArea
//           value={remarks}
//           onChange={(e) => setRemarks(e.target.value)}
//           rows={4}
//         />
//       </div>
//       <Button type="primary" onClick={handleUpload} className="upload-button">
//         Upload
//       </Button>
//     </div>
//   );
// };

// export default FundHouse;import React, { useState } from 'react';

import { Button, Input, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import './mfundhouse.css';

const apiUrl = process.env.REACT_APP_API_URL;
const FundHouse = () => {
  const [file, setFile] = useState(null);
  const [remarks, setRemarks] = useState('');
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (info) => {
    const { file } = info;
    
    console.log('File change event:', info);

    if (file.status === 'removed') {
      setFile(null);
      return;
    }

    const isExcel = /\.(xlsx|xls|csv)$/i.test(file.name);
    if (!isExcel) {
      message.error('You can only upload Excel files!');
      return;
    }

    setFile(file);
    message.success(`${file.name} file selected successfully`);
  };

  const handleUpload = async () => {
    if (!file) {
      message.error('Please choose a file to upload');
      return;
    }
  
    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('remarks', remarks);
  
    try {
      const response = await fetch(`${apiUrl}/fundhouse.php`, {
        method: 'POST',
        body: formData,
        mode: 'cors',
        credentials: 'include',
      });
  
      const data = await response.json(); // Make sure this line is correctly processing JSON
  
      if (data.success) {
        message.success(data.message || 'File uploaded and processed successfully');
        setFile(null);
        setRemarks('');
      } else {
        throw new Error(data.message || 'File upload failed');
      }
    } catch (error) {
      console.error('Upload error:', error);
      message.error('File upload failed: ' + error.message);
    } finally {
      setUploading(false);
    }
  };
  

  return (
    <div className="upload-container">
      <h1>Mutual Fund House</h1>
      <p>Download Template File: <a href="/fund_house_template(1).xlsx" download>Click here</a></p>
      <div className="upload-section">
        <label>Select File:</label>
        <Upload
          accept=".xlsx,.xls,.csv"
          beforeUpload={() => false}
          onChange={handleFileChange}
          fileList={file ? [file] : []}
        >
          <Button icon={<UploadOutlined />} disabled={uploading}>
            Choose Excel File
          </Button>
        </Upload>
      </div>
      <div className="remarks-section">
        <label>Remarks:</label>
        <Input.TextArea
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          rows={4}
        />
      </div>
      <Button 
        type="primary" 
        onClick={handleUpload} 
        className="upload-button"
        loading={uploading}
        disabled={!file || uploading}
      >
        {uploading ? 'Uploading...' : 'Upload'}
      </Button>
    </div>
  );
};

export default FundHouse;