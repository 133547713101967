import React, { useState } from 'react';
import './signup.css';
import { Link, useNavigate } from 'react-router-dom';
import { signup } from './apiservices'; // Ensure the import path is correct

const Signup = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if (password !== confirmPassword) {
        //     setError('Passwords do not match');
        //     return;
        // }
        if (password  !== confirmPassword) {
            setError('Passwords do not match');
            return;
          }
          if (password.length < 8) {
            setError('Password must be at least 8 characters');
            return;
          }
          if (!/[A-Z]/.test(password)) {
            setError('Password must contain at least one uppercase letter');
            return;
          }
          if (!/\d/.test(password)) {
            setError('Password must contain at least one digit');
            return;
          }
          if (!/[^A-Za-z0-9]/.test(password)) {
            setError('Password must contain at least one special character');
            return;
          }
        try {
            const action = "signup";
            const response = await signup({ action,username, email, password });
            console.log("response=",response)
            if (response.success) {
                setSuccessMessage('Registration successful');
                navigate('/login');
            } else {
                setError(response.message);
            }
        } catch (err) {
            setError('Registration failed. Please try again.');
        }
    };

    return (
        <div className="signup-container">
            <form onSubmit={handleSubmit}>
                <h2> Register </h2>
                {successMessage && <p className="success">{successMessage}</p>}
                {error && <p className="error">{error}</p>}
                <div className="input-box">
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <br />
                <div className="input-box">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <br />
                <div className="input-box">
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <br />
                <div className="input-box">
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <br />
                <div>
                    <button
                        style={{
                            width: '45%',
                            height: '45px',
                            background: '#007bff',
                            border: 'none',
                            outline: 'none',
                            borderRadius: '40px',
                            boxShadow: '0 0 10px rgba(0,0,0, .1)',
                            cursor: 'pointer',
                            fontSize: '16px',
                            color: '#333',
                            fontWeight: '700',
                        }}
                        type="submit"
                    >
                        Register
                    </button>
                </div>
                <div className="login-link">
                    <p style={{ marginLeft: '1.3px' }}>
                        Already have an account? <Link to="/login">Login</Link>
                    </p>
                </div>
            </form>
        </div>
    );
};

export default Signup;
