import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import jsPDF from 'jspdf';
 
const Calchild = () => {
  const [name, setName] = useState('');
  const [childName, setChildName] = useState('');
  const [childAge, setChildAge] = useState(1);
  const [educationAge, setEducationAge] = useState('');
  const [educationCost, setEducationCost] = useState('');
  const [inflation, setInflation] = useState('');
  const [newInvestment, setNewInvestment] = useState(4);
  const [uploadedImage, setUploadedImage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [yearsToHigherEducation, setYearsToHigherEducation] = useState(0);
  const [futureValue, setFutureValue] = useState(0);
  const [lumpsumInvestment, setLumpsumInvestment] = useState(0);
  const [sipRequired, setSipRequired] = useState(0);

  const displayImage = (event) => {
    const reader = new FileReader();
    reader.onload = function() {
      setUploadedImage(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  //new function for print in pdf formate
  const handlePrint = () => {
    const pdf = new jsPDF();

    // Define content for PDF
    const content = `
      Dear ${name}, Dream Education planning

      Child Name: ${childName}
      Child age: ${childAge}
      Higher education age: ${educationAge}
      Current cost of higher education: ${educationCost}
      Inflation (%): ${inflation}%
      Return on new investment (%): ${newInvestment}%

      Calculation based on above assumptions
      Future Value of Education Funding: ${futureValue.toFixed(0)}
      Lump Sum Investment Required to Achieve Goal: ${lumpsumInvestment.toFixed(0)}
      SIP Required to Achieve Goal: ${sipRequired.toFixed(0)} `;
       // Add content to PDF
    pdf.text(content, 10, 10);

    // Save PDF
    pdf.save('education_plan.pdf');

  };
  const handleCalculate = () => {
    // Perform calculations
    const currentAgeInt = parseInt(childAge);
    const higherEducationAgeInt = parseInt(educationAge);
    const currentCostFloat = parseFloat(educationCost);
    const inflationRateFloat = parseFloat(inflation) / 100;
    const returnRateFloat = parseFloat(newInvestment) / 100;

    const yearsToEducation = higherEducationAgeInt - currentAgeInt;
    setYearsToHigherEducation(yearsToEducation);

    const futureVal = currentCostFloat * Math.pow(1 + inflationRateFloat, yearsToEducation);
    setFutureValue(futureVal);

    const lumpsumInvest = futureVal / Math.pow(1 + returnRateFloat, yearsToEducation);
    setLumpsumInvestment(lumpsumInvest);

    const monthlyReturnRate = Math.pow(1 + returnRateFloat, 1 / 12);
    const totalMonths = yearsToEducation * 12;

    const sipReq = (futureVal * (monthlyReturnRate - 1)) / (Math.pow(monthlyReturnRate, totalMonths) - 1);
    setSipRequired(sipReq);

    // Toggle modal visibility
    setShowModal(true);
  };
  

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>Dream Education Calculator</h3>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 grid-margin stretch-card text-center" style={{ display: 'block' }}>
            <div className="card">
              <div className="card-body">
                <div className="image-upload">
                  <div className="content_img">
                    <input type="file" id="myFile" onChange={displayImage} />
                    <img id="uploadedImage" src={uploadedImage} alt="Uploaded" height="220px" width="210px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 grid-margin stretch-card" style={{ display: 'block' }}>
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <form id="educationForm">
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="name" className="card-title">Name</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="name"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label htmlFor="childName" className="card-title">Child name</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="childName"
                                placeholder="Child name"
                                value={childName}
                                onChange={(e) => setChildName(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <div className="form-group">
                              <label htmlFor="childAge" className="card-title">Child age</label>
                              <div className="range-slider">
                                <input
                                  className="range-slider__range input-range"
                                  type="range"
                                  value={childAge}
                                  min="0"
                                  max="50"
                                  name="childAge"
                                  id="childAge"
                                  onChange={(e) => setChildAge(e.target.value)}
                                />
                                <input
                                  className="range-slider__value range-value"
                                  type="text"
                                  value={childAge}
                                  id="childAge-value"
                                  readOnly
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <div className="form-group">
                              <label htmlFor="educationAge" className="card-title">Higher education age</label>
                              <div className="range-slider">
                                <input
                                  className="range-slider__range input-range"
                                  type="range"
                                  value={educationAge}
                                  min="18"
                                  max="50"
                                  name="educationAge"
                                  id="educationAge"
                                  onChange={(e) => setEducationAge(e.target.value)}
                                />
                                <input
                                  className="range-slider__value range-value"
                                  type="text"
                                  value={educationAge}
                                  id="educationAge-value"
                                  readOnly
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <div className="form-group">
                              <label htmlFor="educationCost" className="card-title">Current cost of higher education</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="educationCost"
                                placeholder="Current cost of higher education"
                                value={educationCost}
                                onChange={(e) => setEducationCost(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="inflation" className="card-title">Inflation (%)</label>
                              <input
                                type="number"
                                className="form-control text-size"
                                id="inflation"
                                placeholder="Inflation rate"
                                value={inflation}
                                onChange={(e) => setInflation(e.target.value)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label htmlFor="newInvestment" className="card-title">Return on new investment (%)</label>
                              <input
                                type="number"
                                className="form-control text-size"
                                id="newInvestment"
                                placeholder="Return on new investment"
                                value={newInvestment}
                                onChange={(e) => setNewInvestment(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button type="button" className="btn btn-primary" onClick={handleCalculate}>Calculate</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal component */}
      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Dear {name}<br /> Dream Education planning</h5>
                <button type="button" className="close" onClick={closeModal} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {/* Modal body content */}
                <div className="custom-container">
                  <span className="span1"><strong>Child Name:</strong></span>
                  <span className="span2">{childName}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Child age:</strong></span>
                  <span className="span2">{childAge}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Higher education age:</strong></span>
                  <span className="span2">{educationAge}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Current cost of higher education:</strong></span>
                  <span className="span2">{educationCost}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Inflation (%):</strong></span>
                  <span className="span2">{inflation}%</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Return on new investment (%):</strong></span>
                  <span className="span2">{newInvestment}%</span>
                </div>
                <h4>Calculation based on above assumptions</h4>
          <div className="custom-container">
          <span className="span1"><strong>Future Value of Education Funding:</strong></span>
          <span className="span2">{futureValue.toFixed(0)}</span>
        </div>
        <div className="custom-container">
          <span className="span1"><strong>Lump Sum Investment Required to Achieve Goal:</strong></span>
          <span className="span2">{lumpsumInvestment.toFixed(0)}</span>
        </div>
        <div className="custom-container">
          <span className="span1"><strong>SIP Required to Achieve Goal:</strong></span>
          <span className="span2">{sipRequired.toFixed(0)}</span>
        </div>
              </div>
              <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handlePrint}>Print</button>
                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calchild;
