

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import jsPDF from 'jspdf';

const Homeloan = () => {
  const [homePrice, setHomePrice] = useState('');
  const [downPayment, setDownPayment] = useState('');
  const [loanTerm, setLoanTerm] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [additionalExpenses, setAdditionalExpenses] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handlePrint = () => {
    const pdf = new jsPDF();
    const content = `
      Mortgage Calculation Summary:

      Home Price: ${homePrice}
      Down Payment: ${downPayment}
      Loan Term: ${loanTerm} years
      Interest Rate: ${interestRate}%
      Additional Expenses: ${additionalExpenses}

      Monthly Mortgage Payment: ${monthlyPayment.toFixed(2)}
      Total Home-Buying Cost: ${totalCost.toFixed(2)}
    `;
    pdf.text(content, 10, 10);
    pdf.save('mortgage_calculation.pdf');
  };

  const handleCalculate = () => {
    const principal = parseFloat(homePrice) - parseFloat(downPayment);
    const monthlyInterestRate = parseFloat(interestRate) / 100 / 12;
    const numberOfPayments = parseFloat(loanTerm) * 12;

    const monthlyPaymentCalc =
      (principal * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
    setMonthlyPayment(monthlyPaymentCalc);

    const totalCostCalc =
      monthlyPaymentCalc * numberOfPayments + parseFloat(downPayment) + parseFloat(additionalExpenses);
    setTotalCost(totalCostCalc);

    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>Mortgage Calculator</h3>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <form id="mortgageForm">
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="homePrice" className="card-title">Home Price</label>
                              <input
                                type="number"
                                className="form-control"
                                id="homePrice"
                                placeholder="Home Price"
                                value={homePrice}
                                onChange={(e) => setHomePrice(e.target.value)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label htmlFor="downPayment" className="card-title">Down Payment</label>
                              <input
                                type="number"
                                className="form-control"
                                id="downPayment"
                                placeholder="Down Payment"
                                value={downPayment}
                                onChange={(e) => setDownPayment(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="loanTerm" className="card-title">Loan Term (years)</label>
                              <input
                                type="number"
                                className="form-control"
                                id="loanTerm"
                                placeholder="Loan Term"
                                value={loanTerm}
                                onChange={(e) => setLoanTerm(e.target.value)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label htmlFor="interestRate" className="card-title">Interest Rate (%)</label>
                              <input
                                type="number"
                                className="form-control"
                                id="interestRate"
                                placeholder="Interest Rate"
                                value={interestRate}
                                onChange={(e) => setInterestRate(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <div className="form-group">
                              <label htmlFor="additionalExpenses" className="card-title">Additional Expenses</label>
                              <input
                                type="number"
                                className="form-control"
                                id="additionalExpenses"
                                placeholder="Additional Expenses"
                                value={additionalExpenses}
                                onChange={(e) => setAdditionalExpenses(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button type="button" className="btn btn-primary" onClick={handleCalculate}>Calculate</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Mortgage Calculation Summary</h5>
                <button type="button" className="close" onClick={closeModal} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="custom-container">
                  <span className="span1"><strong>Home Price:</strong></span>
                  <span className="span2">{homePrice}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Down Payment:</strong></span>
                  <span className="span2">{downPayment}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Loan Term (years):</strong></span>
                  <span className="span2">{loanTerm}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Interest Rate (%):</strong></span>
                  <span className="span2">{interestRate}%</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Additional Expenses:</strong></span>
                  <span className="span2">{additionalExpenses}</span>
                </div>
                <h4>Calculation based on above assumptions</h4>
                <div className="custom-container">
                  <span className="span1"><strong>Monthly Mortgage Payment:</strong></span>
                  <span className="span2">{monthlyPayment.toFixed(2)}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Total Home-Buying Cost:</strong></span>
                  <span className="span2">{totalCost.toFixed(2)}</span>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handlePrint}>Print</button>
                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Homeloan;
