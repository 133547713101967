import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import jsPDF from 'jspdf';

const Vacation = () => {
  const [name, setName] = useState('');
  const [destination, setDestination] = useState('');
  const [travelCost, setTravelCost] = useState('');
  const [accommodationCost, setAccommodationCost] = useState('');
  const [foodCost, setFoodCost] = useState('');
  const [activitiesCost, setActivitiesCost] = useState('');
  const [uploadedImage, setUploadedImage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [totalCost, setTotalCost] = useState(0);

  const displayImage = (event) => {
    const reader = new FileReader();
    reader.onload = function() {
      setUploadedImage(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handlePrint = () => {
    const pdf = new jsPDF();

    const content = `
      Dear ${name},

      Vacation Budget Planning for Destination: ${destination}

      Travel Cost: ${travelCost}
      Accommodation Cost: ${accommodationCost}
      Food Cost: ${foodCost}
      Activities Cost: ${activitiesCost}

      Total Estimated Cost: ${totalCost.toFixed(2)}
    `;

    pdf.text(content, 10, 10);
    pdf.save('vacation_budget.pdf');
  };

  const handleCalculate = () => {
    const travel = parseFloat(travelCost) || 0;
    const accommodation = parseFloat(accommodationCost) || 0;
    const food = parseFloat(foodCost) || 0;
    const activities = parseFloat(activitiesCost) || 0;

    const total = travel + accommodation + food + activities;
    setTotalCost(total);

    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>Vacation Budget Calculator</h3>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 grid-margin stretch-card text-center" style={{ display: 'block' }}>
            <div className="card">
              <div className="card-body">
                <div className="image-upload">
                  <div className="content_img">
                    <input type="file" id="myFile" onChange={displayImage} />
                    <img id="uploadedImage" src={uploadedImage} alt="Uploaded" height="220px" width="210px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 grid-margin stretch-card" style={{ display: 'block' }}>
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <form id="vacationForm">
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label htmlFor="name" className="card-title">Name</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="name"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label htmlFor="destination" className="card-title">Destination</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="destination"
                                placeholder="Destination"
                                value={destination}
                                onChange={(e) => setDestination(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <div className="form-group">
                              <label htmlFor="travelCost" className="card-title">Travel Cost</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="travelCost"
                                placeholder="Travel Cost"
                                value={travelCost}
                                onChange={(e) => setTravelCost(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <div className="form-group">
                              <label htmlFor="accommodationCost" className="card-title">Accommodation Cost</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="accommodationCost"
                                placeholder="Accommodation Cost"
                                value={accommodationCost}
                                onChange={(e) => setAccommodationCost(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <div className="form-group">
                              <label htmlFor="foodCost" className="card-title">Food Cost</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="foodCost"
                                placeholder="Food Cost"
                                value={foodCost}
                                onChange={(e) => setFoodCost(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <div className="form-group">
                              <label htmlFor="activitiesCost" className="card-title">Activities Cost</label>
                              <input
                                type="text"
                                className="form-control text-size"
                                id="activitiesCost"
                                placeholder="Activities Cost"
                                value={activitiesCost}
                                onChange={(e) => setActivitiesCost(e.target.value)}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button type="button" className="btn btn-primary" onClick={handleCalculate}>Calculate</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Dear {name},<br /> Vacation Budget Planning</h5>
                <button type="button" className="close" onClick={closeModal} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="custom-container">
                  <span className="span1"><strong>Destination:</strong></span>
                  <span className="span2">{destination}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Travel Cost:</strong></span>
                  <span className="span2">{travelCost}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Accommodation Cost:</strong></span>
                  <span className="span2">{accommodationCost}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Food Cost:</strong></span>
                  <span className="span2">{foodCost}</span>
                </div>
                <div className="custom-container">
                  <span className="span1"><strong>Activities Cost:</strong></span>
                  <span className="span2">{activitiesCost}</span>
                </div>
                <h4>Total Estimated Cost</h4>
                <div className="custom-container">
                  <span className="span1"><strong>Total Cost:</strong></span>
                  <span className="span2">{totalCost.toFixed(2)}</span>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handlePrint}>Print</button>
                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Vacation;
