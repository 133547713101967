import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { changePassword } from './apiservices'; // Assume this function exists in your apiservices.js

const Changepassword = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        if (values.newPassword !== values.confirmPassword) {
            message.error('New passwords do not match');
            return;
        }

        setLoading(true);
        try {
            const response = await changePassword({
                action: 'changePassword',
                currentPassword: values.currentPassword,
                newPassword: values.newPassword
            });

            if (response.success) {
                message.success('Password changed successfully');
                form.resetFields();
            } else {
                message.error(response.message || 'Failed to change password');
            }
        } catch (error) {
            console.error('Change password error:', error);
            message.error('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px' }}>
            <h2>Change Password</h2>
            <Form
                form={form}
                name="changePassword"
                onFinish={onFinish}
                layout="vertical"
            >
                <Form.Item
                    name="currentPassword"
                    label="Current Password"
                    rules={[{ required: true, message: 'Please input your current password!' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="newPassword"
                    label="New Password"
                    rules={[
                        { required: true, message: 'Please input your new password!' },
                        { min: 8, message: 'Password must be at least 8 characters long' },
                        { 
                            pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                            message: 'Password must contain at least one uppercase letter, one number, and one special character'
                        }
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirmPassword"
                    label="Confirm New Password"
                    dependencies={['newPassword']}
                    rules={[
                        { required: true, message: 'Please confirm your new password!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Change Password
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Changepassword;