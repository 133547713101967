import { Button, Input, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import './mm.css'

const apiUrl = process.env.REACT_APP_API_URL;
const MFund = () => {
  const [file, setFile] = useState(null);
  const [remarks, setRemarks] = useState('');
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (info) => {
    const { file } = info;
    
    console.log('File change event:', info);

    if (file.status === 'removed') {
      setFile(null);
      return;
    }

    const isExcel = /\.(xlsx|xls|csv)$/i.test(file.name);
    if (!isExcel) {
      message.error('You can only upload Excel files!');
      return;
    }

    setFile(file);
    message.success(`${file.name} file selected successfully`);
  };

  const handleUpload = async () => {
    if (!file) {
      message.error('Please choose a file to upload');
      return;
    }
  
    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('remarks', remarks);
  
    try {
      const response = await fetch(`${apiUrl}/mutualfunds.php`, {
        method: 'POST',
        body: formData,
        mode: 'cors',
        credentials: 'include',
      });
  
      const data = await response.json(); // Make sure this line is correctly processing JSON
  
      if (data.success) {
        message.success(data.message || 'File uploaded and processed successfully');
        setFile(null);
        setRemarks('');
      } else {
        throw new Error(data.message || 'File upload failed');
      }
    } catch (error) {
      console.error('Upload error:', error);
      message.error('File upload failed: ' + error.message);
    } finally {
      setUploading(false);
    }
  };
  

  return (
    <div className="upload-container">
      <h1>Mutual Funds</h1>
      <p>Download Template File: <a href="/FullSchemeMaster - ARN-249603.xlsx" download>Click here</a></p>
      <div className="upload-section">
        <label>Select File:</label>
        <Upload
          accept=".xlsx,.xls,.csv"
          beforeUpload={() => false}
          onChange={handleFileChange}
          fileList={file ? [file] : []}
        >
          <Button icon={<UploadOutlined />} disabled={uploading}>
            Choose Excel File
          </Button>
        </Upload>
      </div>
      <div className="remarks-section">
        <label>Remarks:</label>
        <Input.TextArea
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          rows={4}
        />
      </div>
      <Button 
        type="primary" 
        onClick={handleUpload} 
        className="upload-button"
        loading={uploading}
        disabled={!file || uploading}
      >
        {uploading ? 'Uploading...' : 'Upload'}
      </Button>
    </div>
  );
};

export default MFund;