
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './reset.css';

const Reset = () => {
    const { token } = useParams();
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        setNewPassword(password);

        // Check password strength
        if (password.length < 8) {
            setError('Password must be at least 8 characters long');
        } else if (!/\d/.test(password)) {
            setError('Password must contain at least one number');
        } else if (!/[!@#$%^&*]/.test(password)) {
            setError('Password must contain at least one special character');
        } else {
            setError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
        } else {
            try {
                const response = await fetch('http://localhost:5000/api/reset-password', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ token, email, newPassword })
                });
                const data = await response.json();
                if (response.ok) {
                    setSuccessMessage(data.message);
                    await sendNotification(data.email);
                } else {
                    setError(data.message);
                }
            } catch (err) {
                setError('Failed to reset password. Please try again later.');
            }
        }
    };

    const sendNotification = async (email) => {
        try {
            const response = await fetch('http://localhost:5000/api/send-notification', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });
            const data = await response.json();
            if (!response.ok) {
                setError('Failed to send notification email');
            }
        } catch (err) {
            setError('Failed to send notification email');
        }
    };

    return (
        <div className="password-reset-container" style={{ marginTop: '200px'}}>
            <h2>Reset Password</h2>
            <form onSubmit={handleSubmit}>
                <div className="input-box" style={{ marginLeft: '15px'}}>
                    <input
                        type="email"
                        placeholder="Enter Your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="input-box" style={{ marginLeft: '15px'}}>
                    <input
                        type="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={handlePasswordChange}
                        required
                    />
                </div>
                <div className="input-box" style={{ marginLeft: '15px'}}>
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <p className="error">{error}</p>}
                {successMessage && <p className="success">{successMessage}</p>}
                <button type="submit" disabled={error || successMessage}
                 style={{width: '45%',borderRadius: '40px',
                            boxShadow: '0  0 10px rgba(0,0,0, .1)',
                            cursor: 'pointer',
                            fontSize: '16px',marginLeft:'105px'}}>Reset Password</button>
            </form>
        </div>
    );
};

export default Reset;

