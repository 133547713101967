
import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, Form, message, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import './profile.css';


const apiUrl = process.env.REACT_APP_API_URL;
const Profile = () => {
  const [photoUrl, setPhotoUrl] = useState('');
  const [userData, setUserData] = useState({
    phone: '',
    occupation: '',
    address: '',
    username: '',
    email: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  const fileInput = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          message.error('No token found. Please log in.');
          navigate('/login');
          return;
        }

        const response = await fetch(`${apiUrl}/userdata.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ action: 'fetchProfile' })
        });

        const data = await response.json();
        if (data.success) {
          setUserData(data.data);
          form.setFieldsValue(data.data); // Set initial form values
        } else {
          if (data.message === 'Not authorized, token failed') {
            message.error('Session expired. Please log in again.');
            localStorage.removeItem('token');
            navigate('/login');
          } else {
            message.error('Failed to fetch user data');
          }
        }
      } catch (error) {
        message.error('An error occurred while fetching user data');
      }
    };

    fetchUserProfile();
  }, [form, navigate]);

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setPhotoUrl(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSave = async (values) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        message.error('No token found. Please log in.');
        navigate('/login');
        return;
      }

      const response = await fetch(`${apiUrl}/userdata.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ action: 'updateProfile', ...values })
      });

      const data = await response.json();
      if (data.success) {
        setUserData(values);
        message.success('Profile updated successfully');
        form.setFieldsValue(values); // Update form values
        setIsEditing(false);
      } else {
        message.error('Failed to update profile');
      }
    } catch (error) {
      message.error('An error occurred while saving data');
    }
  };


  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <div className="profile-container">
      <div className='profile-content'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '120px',
              height: '120px',
              borderRadius: '50%',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #ccc',
              marginRight: '20px'
            }}
          >
            <img
              src={photoUrl || 'https://via.placeholder.com/100'}
              alt="Profile"
              style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '50%' }}
            />
          </div>
          <div>
            <Button onClick={() => fileInput.current.click()}>Upload Photo</Button>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileUpload(e.target.files[0])}
              style={{ display: 'none' }}
              ref={fileInput}
            />
            <br/>
            <div className="profile-info">
              <h2>{userData.username}</h2>
              <p>{userData.email}</p>
            </div>
          </div>
        </div>
        <Form
          form={form}
          layout="vertical"
          initialValues={userData}
          onFinish={handleSave}
          className="profile-form"
        >
          <Form.Item label="Phone Number :" name="phone">
            <Input disabled={!isEditing} />
          </Form.Item>
          <Form.Item label="Occupation :" name="occupation">
            <Input disabled={!isEditing} />
          </Form.Item>
          <Form.Item label="Address :" name="address">
            <Input disabled={!isEditing} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ display: isEditing ? 'inline-block' : 'none' }}>
              Save
            </Button>
            <Button type="default" onClick={handleCancel} style={{ display: isEditing ? 'inline-block' : 'none' }}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleEdit} style={{ display: isEditing ? 'none' : 'inline-block' }}>
              Edit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Profile;
