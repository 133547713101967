
// export default Login;
import React, { useState } from 'react';
import './login.css';
import { Link, useNavigate } from 'react-router-dom';
import { MdEmail } from "react-icons/md";
import { login } from './apiservices';

const Login = ({ setUsername }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const action = "login";
            const response = await login({ action, email, password });
            if (response.success) {
                // Save token in local storage
                localStorage.setItem('token', response.token);
                localStorage.setItem('username', response.user.name);
                setUsername(response.user.name); // Update the username state in Index
                // Navigate to the protected route based on user type
                if (response.user.isAdmin) {
                    navigate('/adminDashBoard');
                } else {
                    navigate('/'); // Redirect to the homepage
                }
            } else {
                setError(response.message);
            }
        } catch (err) {
            setError('Login failed. Please check your credentials.');
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit}>
                <h2>Login</h2>
                {error && <p className="error">{error}</p>}
                <div className="input1-box">
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <MdEmail className="icon" />
                </div>
                <br />
                <div className="input1-box">
                    <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </div>
                <br />
                <br />
                <div className="remember-forgot" style={{ marginRight: '20px' }}>
                    <label><input type="checkbox" /> Remember me</label>
                    <Link to="/reset" style={{ fontSize: '100%', fontStyle: 'bold', marginLeft: '100px' }}>Forgot password?</Link>
                </div>
                <br />
                <div>
                    <button style={{ width: '75%', height: '45px', background: '#fff', border: 'none', outline: 'none', borderRadius: '40px', boxShadow: '0 0 10px rgba(0,0,0, .1)', cursor: 'pointer', fontSize: '16px', color: '#333', fontWeight: '700', marginLeft: '6px' }} type="submit">Login</button>
                </div>
                <div className="register-link" style={{ marginLeft: '50px' }}>
                    <p style={{ marginRight: '50px' }}>Don't have an account? <Link to="/signup"> Register</Link></p>
                </div>
            </form>
        </div>
    );
};

export default Login;
